import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    Text,
    Link
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Papa from "papaparse"
import { createProduct, getLastProductId } from '../../redux/Product/productCalls'
import productTemplateAuto from "../../templates/productTemplate_auto.xlsx"
import productTemplateMan from "../../templates/productTemplate_man.xlsx"

export default function ImportProductsModal({ isOpen, onClose }) {
    const dispatch = useDispatch()
    const [userJSON, setUserJSON] = useState()
    const [uploadedCSV, setUploadedCSV] = useState(null)
    const {account_id} = useSelector((state) => state.account)
    const { product_number } = useSelector((state) => state.account)
    const { product_number_status } = useSelector((state) => state.account)
    var productCount = parseInt(product_number) + 1

    // HR - on form submit, set "initializeProducts" task to true, making a green check appear
    // file upload does not actually send file anywhere
    function handleProductUpload(e) {
        setUploadedCSV(e.target.files[0])
    }

    function handleCSVSubmit(e) {
        e.preventDefault()
        Papa.parse(uploadedCSV, {
            header: true,
            complete: (results) => {
                setUserJSON(results)
            }
        })
    }

    useEffect(() => {
        if (userJSON && userJSON.errors.length === 0 && userJSON.data.length > 0) {
            userJSON.data.forEach(product => {
                product.account_id = account_id
                if(!product.product_id){
                    product.product_id = String(productCount)
                }
                createProduct(product, dispatch)
                productCount += 1
                onClose()
            });
        }
    }, [userJSON])

    useEffect(() => {
        // HR - if product number was not recently set in settings, use number fetched from last product to increment
        if (product_number_status !== "updated" && isOpen) {
            const getLastIdFn = async () => { getLastProductId(account_id, dispatch) }
            getLastIdFn()
        }
    }, [isOpen])

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Import Products</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form onSubmit={handleCSVSubmit}>
                            <VStack spacing={"1rem"}>
                                <Text>To help format your file correctly, we have provided a spreadsheet for you to download and fill out and upload below</Text>
                                <Text><b>Note:</b> There are 2 separate templates based on whether you want to manually input or automatically generate product ids</Text>
                                <Text><b>Do NOT change column headers</b></Text>
                                {/* HR - user can click to download excel template to fill out product data */}
                                <a href={productTemplateAuto} download="ProductTemplateAuto" target='_blank'>
                                    <Button colorScheme='green'>Download Auto ID Template</Button>
                                </a>
                                <a href={productTemplateMan} download="ProductTemplateManual" target='_blank'>
                                    <Button>Download Manual ID Template</Button>
                                </a>
                                <FormControl>
                                    <FormLabel>Upload CSV File</FormLabel>
                                    <Input placeholder='Choose File' type="file" accept='.csv' variant={"flushed"} onChange={handleProductUpload} />
                                </FormControl>
                                <Button colorScheme='blue' mr={3} type='submit'>
                                    Save
                                </Button>
                            </VStack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}