import { createSlice } from "@reduxjs/toolkit";

export const accountSlice = createSlice({
    name: "account",
    initialState: {
        account_id: null,
        account_name: null,
        account_initial: null,
        account_phone: null,
        account_email: null,
        account_fax: null,
        account_logo: null,
        account_terms: null,
        default_fob: null,
        default_valid_for: null,
        default_terms_text: {},
        address_1: null,
        address_2: null,
        city: null,
        state: null,
        zip: null,
        country: null,
        currency: null,
        product_number: null,
        document_number: null,
        subscription: {},
        product_number_status: 'static',
        document_number_status: 'static',
        createAccountProgress: false,
        getAccountProgress: false,
        updateAccountProgress: false,
        deleteAccountProgress: false,
        error: false,
    },
    reducers: {
        // create account
        createAccountStart: (state) => {
            state.createAccountProgress = true
            state.error = false
        },
        createAccountSuccess: (state, action) => {
            state.createAccountProgress = false
        },
        createAccountFailure: (state) => {
            state.error = true
            state.createAccountProgress = false
        },

        // get account
        getAccountStart: (state) => {
            state.getAccountProgress = true
            state.error = false
        },
        getAccountSuccess: (state, action) => {
            state.account_id = action.payload.account_id;
            state.account_name = action.payload.account_name;
            var first_letter = action.payload.account_name.substring(0, 1);
            state.account_initial = first_letter;
            state.account_phone = action.payload.phone;
            state.account_email = action.payload.email;
            state.account_fax = action.payload.fax;
            state.address_1 = action.payload.address_1;
            state.address_2 = action.payload.address_2;
            state.city = action.payload.city;
            state.state = action.payload.state;
            state.country = action.payload.country;
            state.zip = action.payload.zip;
            state.currency = action.payload.currency;
            state.product_number = action.payload.product_number;
            state.document_number = action.payload.document_number;
            state.account_logo = action.payload.logo
            state.account_terms = action.payload.terms
            state.default_fob = action.payload.default_fob
            state.default_valid_for = action.payload.default_valid_for
            state.default_terms_text = action.payload.default_terms_text
            state.subscription = action.payload.subscription
            state.getAccountProgress = false
        },
        getAccountFailure: (state) => {
            state.error = true
            state.getAccountProgress = false
        },

        // edit account
        updateAccountStart: (state) => {
            state.updateAccountProgress = true
            state.error = false
        },
        updateAccountSuccess: (state, action) => {
            state.account_name = action.payload.name;
            var first_letter = action.payload.name.substring(0, 1);
            state.account_initial = first_letter;
            state.account_phone = action.payload.phone;
            state.account_email = action.payload.email;
            state.account_fax = action.payload.fax;
            state.address_1 = action.payload.address_1;
            state.address_2 = action.payload.address_2;
            state.city = action.payload.city;
            state.state = action.payload.state;
            state.country = action.payload.country;
            state.zip = action.payload.zip;
            state.currency = action.payload.currency;
            state.product_number = action.payload.product_number;
            state.document_number = action.payload.document_number;
            state.account_logo = action.payload.logo;
            state.account_terms = action.payload.terms;
            state.default_fob = action.payload.default_fob
            state.default_valid_for = action.payload.default_valid_for
            state.default_terms_text = action.payload.default_terms_text
            state.updateAccountProgress = false
        },
        updateAccountFailure: (state) => {
            state.error = true
            state.updateAccountProgress = false
        },
        updateProductNumber: (state, action) => {
            if (action.payload.mode === "fetch") {
                state.product_number = parseInt(action.payload.product_number.replace(/\D/g, ''))
                state.product_number_status = "static"
            } else if (action.payload.mode === "increment") {
                var prodNumCurr = state.product_number
                if (typeof prodNumCurr === "string") {
                    var prodNumOnly = prodNumCurr.replace(/\D/g, '')
                }
                state.product_number = parseInt(prodNumOnly) + 1
                state.product_number_status = "static"
            } else if (action.payload.mode === "set") {
                state.product_number = parseInt(action.payload.product_number.replace(/\D/g, ''))
                state.product_number_status = "updated"
            }
        },
        updateDocumentNumber: (state, action) => {
            // HR - remove letters from document_number in order to easily increment
            console.log(action.payload.document_number)
            const numberVal = Number(action.payload.document_number.replace(/\D/g, ''))
            if (action.payload.mode === "fetch") {
                state.document_number = numberVal
                state.document_number_status = "static"
            } else if (action.payload.mode === "increment") {
                state.document_number = parseInt(state.document_number) + 1
                state.document_number_status = "static"
            } else if (action.payload.mode === "set") {
                state.document_number = numberVal
                state.document_number_status = "updated"
            }
        },
        resetProductNumberStatus: (state) => {
            state.product_number_status = "static";
        },
        deleteTermsSuccess: (state) => {
            state.account_terms = ""
        },

        // get account
        deleteAccountStart: (state) => {
            state.deleteAccountProgress = true
            state.error = false
        },
        deleteAccountSuccess: (state, action) => {
            state.accounts = state.accounts.filter(
                (account) => account._id !== action.payload
            )
            state.deleteAccountProgress = false
        },
        deleteAccountFailure: (state) => {
            state.error = true
            state.deleteAccountProgress = false
        }

    },
})

export const {
    createAccountStart,
    createAccountSuccess,
    createAccountFailure,
    getAccountStart,
    getAccountSuccess,
    getAccountFailure,
    updateAccountStart,
    updateAccountSuccess,
    updateAccountFailure,
    updateProductNumber,
    updateDocumentNumber,
    resetProductNumberStatus,
    deleteTermsSuccess,
    deleteAccountStart,
    deleteAccountSuccess,
    deleteAccountFailure,
} = accountSlice.actions

export default accountSlice.reducer