import TanFormTable from '../TanStackTable/TanFormTable';
import productPOColumns from "../TanStackTable/Columns/Products/productPOColumns"
import editProductPOColumns from "../TanStackTable/Columns/Products/editProductPOColumns"
import productQuoteColumns from '../TanStackTable/Columns/Products/productQuoteColumns';
import editProductQuoteColumns from '../TanStackTable/Columns/Products/editProductQuoteColumns';
import editDiscountProductQuoteColumns from '../TanStackTable/Columns/Products/editDiscountProductQuoteColumns';
import productDiscountQuoteColumns from '../TanStackTable/Columns/Products/productDiscountQuoteColumns'
import { useDisclosure, Box, FormControl, FormLabel, Input, Stack, Container, Select, Textarea, Button, HStack, Tooltip, Divider } from '@chakra-ui/react';
import ProductModal from '../ProductModal';
import { useEffect, useState } from 'react';
import { itemizeSuccess, setTermsSuccess } from '../../redux/CreateDoc/createDocSlice';
import { termsContentChange, termsContentSave } from '../../redux/PaymentTerm/paymentTermSlice'
import { useDispatch, useSelector } from 'react-redux';
import handleInputChange from '../../helper/inputChange';
import { toast } from 'react-toastify';
import { FiInfo } from 'react-icons/fi';
import { getPaymentTerms } from '../../redux/PaymentTerm/paymentTermCalls';

export default function ItemizePDF({ type, mode, columns, setColumns }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [productRows, setProductRows] = useState({})
    const [productIndicies, setProductIndicies] = useState(null)
    const dispatch = useDispatch()
    const { account_id } = useSelector((state) => state.account)
    const { products } = useSelector((state) => state.createDoc)
    const { getProductsProgress } = useSelector((state) => state.products)
    const { terms_content } = useSelector((state) => state.createDoc)
    const { product_indicies } = useSelector((state) => state.createDoc)
    const { terms_content_change } = useSelector((state) => state.paymentTerms)
    const { payment_terms } = useSelector((state) => state.paymentTerms)
    const { default_fob } = useSelector((state) => state.account)
    const { discounts_enabled } = useSelector((state) => state.createDoc)
    const [defaultTerm, setDefaultTerm] = useState({})
    const [terms, setTerms] = useState({
        fob: default_fob,
        payment: "",
        delivery: "",
    })

    useEffect(() => {
        setProductIndicies(product_indicies)
    }, [product_indicies])


    useEffect(() => {
        if (!terms_content_change) {
            dispatch(termsContentChange())
        }
    }, [terms])

    useEffect(() => {
        setTerms({ ...terms, fob: default_fob })
    }, [default_fob])

    useEffect(() => {
        // HR - if existing state for terms_content, set state to saved values
        if ((type === "Quote" || type === "Invoice") && terms_content && Object.keys(terms_content).length > 0) {
            setTerms({
                fob: terms_content.fob,
                payment: terms_content.payment,
                delivery: terms_content.delivery,
            })
        } else {
            getPaymentTerms(account_id, dispatch)
        }
    }, [])

    function inputChange(e) {
        handleInputChange(e.target, setTerms)
        // HR - if user updates payment terms, delivery terms automatically set to value in paymentDelivery dictionary
        if (e.target.name === "default") {
            setDefaultTerm(e.target.value)
            var selectedTerm = payment_terms.filter(payment_term => (
                payment_term._id === e.target.value
            ))
            setTerms((terms) => ({ ...terms, ['payment']: selectedTerm[0].terms, ['delivery']: selectedTerm[0].delivery }))
        }
    }

    function handleSubmitForm(e) {
        e.preventDefault()
        dispatch(setTermsSuccess(terms))
        dispatch(termsContentSave())
        toast.success("Successfully saved!")
    }

    function handleDoneClick() {
        // HR - check to make sure user has selected products before sending them to redux state, 
        // will error if state is empty in next stage
        if (productRows.length > 0) {
            dispatch(itemizeSuccess({ productRows: productRows, productIndicies: productIndicies }))
        }
        onClose()
    }

    // HR - this if branch is used to select the appropriate column definitions based on doc_type and mode (edit, view)
    useEffect(() => {
        if (type === "PO") {
            if (mode === 'view') { // HR - view PO columns
                setColumns(productPOColumns)
            } else { // HR - edit PO columns
                setColumns(editProductPOColumns)
            }
        } else { // HR - quotes and invoices
            if (mode === 'view') { // HR - view quote/invoice columns
                setColumns(productQuoteColumns)
            } else { // HR - edit quote/invoice columns
                setColumns(editProductQuoteColumns)
            }
        }
    }, [])


    // HR - if user toggles discount on/off, update table columns to include/exclude discount columns
    useEffect(() => {
        if ((type === "Quote" || type === "Invoice") && mode === 'view') {
            if (discounts_enabled) { // HR - view quote/invoice columns with DISCOUNTS
                setColumns(productDiscountQuoteColumns)
            } else { // HR - view quote/invoice columns
                setColumns(productQuoteColumns)
            }
        } else if (type === "Quote" || type === "Invoice") {
            if (discounts_enabled) { // HR - edit quote/invoice columns with DISCOUNTS
                setColumns(editDiscountProductQuoteColumns)
            } else { // HR - edit quote/invoice columns
                setColumns(editProductQuoteColumns)
            }
        }
    }, [discounts_enabled])


    return (
        <Box >
            {/* HR - notice this is tanFORMtable, which includes the add exisiting/new buttons and removes filters */}
            <Divider marginBottom={'2rem'} />
            <Stack direction={['column', 'column', 'column', 'row']}>
                {mode !== 'view' && <ProductModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} setProductRows={setProductRows} handleClick={handleDoneClick}
                    type={type} setProductIndicies={setProductIndicies} />}
                {!getProductsProgress && <>
                    <TanFormTable columns={columns} defaultData={products} modalOpen={onOpen} type={type} contentType="products" mode={mode} />
                    {(type === "Quote" || type === "Invoice") &&
                        <form onSubmit={handleSubmitForm}>
                            <Container centerContent >
                                <Stack spacing={5} mt={5} w={['90vw','60vw', '40vw', '25vw', '20vw']} padding={4} borderRadius={5} backgroundColor={'#38a16970'}>
                                    <FormControl isRequired>
                                        <FormLabel>F.O.B.</FormLabel>
                                        <Input
                                            value={terms.fob}
                                            name="fob"
                                            onChange={inputChange}
                                            type="text"
                                            variant={"filled"}
                                            disabled={mode === 'view' ? true : false}
                                            style={mode === 'view' ? { opacity: '1', cursor: 'default' } : null}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <HStack alignItems={'baseline'}>
                                            <FormLabel>Payment Term Defaults</FormLabel>
                                            <Tooltip label='These defaults can be managed from the Settings page' fontSize='sm' placement="right-start">
                                                <span><FiInfo /></span>
                                            </Tooltip>
                                        </HStack>
                                        <Select
                                            value={defaultTerm}
                                            name="default"
                                            placeholder='Select a default option'
                                            onChange={inputChange}
                                            variant={"filled"}
                                            disabled={mode === 'view' ? true : false}
                                            style={mode === 'view' ? { opacity: '1', cursor: 'default' } : null}
                                        >
                                            {/* HR - map payment_terms created in settings to become select options */}
                                            {payment_terms.map(payment_term => (
                                                <option value={payment_term._id} key={payment_term._id}>{payment_term.terms}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Terms</FormLabel>
                                        <Textarea
                                            value={terms.payment}
                                            name="payment"
                                            onChange={inputChange}
                                            type="text"
                                            variant={"filled"}
                                            disabled={mode === 'view' ? true : false}
                                            style={mode === 'view' ? { opacity: '1', cursor: 'default' } : null}
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Delivery</FormLabel>
                                        <Textarea
                                            value={terms.delivery}
                                            name="delivery"
                                            onChange={inputChange}
                                            type="text"
                                            variant={"filled"}
                                            disabled={mode === 'view' ? true : false}
                                            style={mode === 'view' ? { opacity: '1', cursor: 'default' } : null}
                                        />
                                    </FormControl>
                                    {mode !== 'view' && <Button colorScheme={terms_content_change ? "green" : "gray"} isDisabled={!terms_content_change} type='submit'>Save</Button>}
                                </Stack>
                            </Container>
                        </form>}
                </>}
            </Stack>
        </Box>
    )
}