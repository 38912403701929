import { toast } from 'react-toastify'
import * as actions from "./contactSlice"
import getApiUri from '../../helper/getApiUri'
import axios from 'axios'

const apiUrl = getApiUri() + "/contacts"

export const createContact = async (payload, dispatch) => {
    dispatch(actions.createContactStart())
    try {
        const { data } = await axios.post(apiUrl, payload)
        dispatch(actions.createContactSuccess(data.data))
        toast.success(data.message);
        return true
    } catch (error) {
        dispatch(actions.createContactFailure())
        if (error.response) {
            toast.error(error.response.data.message);
        } else {
            toast.error("Something went wrong!")
        }
        return false
    }
}

export const getContacts = async (payload, dispatch) => {
    dispatch(actions.getContactsStart())
    try {
        const { data } = await axios.get(apiUrl + `/${payload}`)
        dispatch(actions.getContactsSuccess(data.data))
        return true
    } catch (error) {
        console.log(error)
        dispatch(actions.getContactsFailure())
        return false
    }
}

export const updateContact = async (payload, dispatch) => {
    dispatch(actions.updateContactStart())
    try {
        const url = apiUrl + '/' + payload.id
        const { data } = await axios.put(url, payload.data)
        dispatch(actions.updateContactSuccess(data.data))
        toast.success(data.message)
        return true
    } catch (error) {
        toast.error("Something went wrong!");
        dispatch(actions.updateContactFailure())
        return false
    }
}

export const deleteContact = async (id, dispatch) => {
    dispatch(actions.deleteContactStart())
    try {
        const { data } = await axios.delete(apiUrl + "/" + id)
        dispatch(actions.deleteContactSuccess(id))
        toast.success(data.message)
        return true
    } catch (error) {
        toast.error("Something went wrong!");
        dispatch(actions.deleteContactFailure())
        return false
    }
}