import { createSlice } from "@reduxjs/toolkit";

export const resourceSlice = createSlice({
    name: "resources",
    initialState: {
        resources: [],
        createResourceProgress: false,
        getResourcesProgress: false,
        updateResourceProgress: false,
        deleteResourceProgress: false,
        error: false,
    },
    reducers: {
        // create Resource
        createResourceStart: (state) => {
            state.createResourceProgress = true
        },
        createResourceSuccess: (state, action) => {
            state.error = false
            state.resources.push(action.payload)
            state.createResourceProgress = false
        },
        createResourceFailure: (state) => {
            state.error = true
            state.createResourceProgress = false
        },

        // get resources
        getResourcesStart: (state) => {
            state.getResourcesProgress = true
        },
        getResourcesSuccess: (state, action) => {
            state.error = false
            state.resources = action.payload
            state.getResourcesProgress = false
        },
        getResourcesFailure: (state) => {
            state.error = true
            state.getResourcesProgress = false
        },

        // edit resource
        updateResourceStart: (state) => {
            state.updateResourceProgress = true
        },
        updateResourceSuccess: (state, action) => {
            state.error = false
            state.resources.push(action.payload)
            state.updateResourceProgress = false
        },
        updateResourceFailure: (state) => {
            state.error = true
            state.updateResourceProgress = false
        },

        // get resource
        deleteResourceStart: (state) => {
            state.deleteResourceProgress = true
        },
        deleteResourceSuccess: (state, action) => {
            state.error = false
            state.resources = state.resources.filter(
                (resource) => resource._id !== action.payload
            )
            state.deleteResourceProgress = false
        },
        deleteResourceFailure: (state) => {
            state.error = true
            state.deleteResourceProgress = false
        },

    },
})

export const {
    createResourceStart,
    createResourceSuccess,
    createResourceFailure,
    getResourcesStart,
    getResourcesSuccess,
    getResourcesFailure,
    updateResourceStart,
    updateResourceSuccess,
    updateResourceFailure,
    deleteResourceStart,
    deleteResourceSuccess,
    deleteResourceFailure,
} = resourceSlice.actions

export default resourceSlice.reducer