import { Input, Select } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import { updateContact } from "../../redux/Contact/contactCalls"
import { updateProduct } from "../../redux/Product/productCalls"
import { updateUser } from "../../redux/User/userCalls"
import { updateItemize } from "../../redux/CreateDoc/createDocSlice"
import { useDispatch, useSelector } from "react-redux"

export default function TableCell({ getValue, row, column, table, disableCell = false, tableType = null }) {
    const initialValue = getValue()
    const columnMeta = column.columnDef
    const tableMeta = table.options.meta
    const [value, setValue] = useState(initialValue)
    const header = column.columnDef.header
    const dispatch = useDispatch()
    const {currency} = useSelector((state) => state.account)

    // HR - created formatter to transform 2500 -> $2,500.00, or chosen currency (supported = USD, GBP, EUR)
    var endValue = value
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
    })

    if (header === "Price" || header === "Cost" || header === "Total" || header === "List Price" || header === "(List) Price" || header === "Grand Total") {
        endValue = formatter.format(value)
    } else if (header === "Discount/Markup") {
        endValue = parseFloat(value).toFixed(2) + "%"
        if(row.original.discount_options.use_markup){ // HR - markup
            endValue = "+" + parseFloat(value).toFixed(2) + "%"
        }else{ // HR - discount
            endValue = "-" + parseFloat(value).toFixed(2) + "%"
        }
    }

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const onBlur = () => {
        tableMeta?.updateData(row.index, column.id, value)
    }

    const onSelectChange = (e) => {
        setValue(e.target.value)
        tableMeta?.updateData(row.index, column.id, e.target.value)
    }

    function changeTextColor(){
        if(columnMeta.id === "discount_options.percentage"){
            if(row.original.discount_options.use_markup){ // HR - markup color
                return "green"
            }else{ // HR - discount color
                return "red"
            }
        }else{ // HR - default color
            return null
        }
    }

    async function handleKeyDown(e) {
        if (e.keyCode === 13) {
            // HR - responsible for updating table state data
            tableMeta?.updateData(row.index, column.id, value)
            // HR - changes row from active input to standard row
            tableMeta?.setEditedRows(old => {
                return ({
                    ...old,
                    [row.id]: !old[row.id]
                })
            })

            if (tableType) {
                if (tableType === "contact") {
                    const payload = { data: row.original, id: row.original._id }
                    await updateContact(payload, dispatch)
                } else if (tableType === "product") {
                    const payload = { data: row.original, id: row.original._id }
                    await updateProduct(payload, dispatch)
                } else if (tableType === "user") {
                    const payload = { update: "role", data: row.original.role, id: row.original._id }
                    await updateUser(payload, dispatch)
                } else if (tableType === "createPDF") {
                    dispatch(updateItemize(row.original))
                }
                return
            }
        }
    }

    if (tableMeta?.editedRows[row.id]) {
        return columnMeta?.type === "select" ? (
            <Select onChange={onSelectChange} value={initialValue}>
                {columnMeta?.options?.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>
        ) : (
            <Input
                value={value}
                onChange={e => setValue(e.target.value)}
                onBlur={onBlur}
                type={columnMeta.type}
                w={(value && value.length > 30) ? 32 + 'ch' : 'fit-content'}
                variant={'outline'}
                isDisabled={disableCell}
                size="sm"
            // onKeyDown={handleKeyDown}
            // onInput={(e) => e.target.size = e.target.value.length + 1}
            />
        )
    }
    return <Input
        value={endValue}
        type={'text'}
        w={(value && value.length > 30) ? 32 + 'ch' : 'fit-content'}
        variant={"unstyled"}
        disabled
        size="sm"
        style={{ opacity: '1', cursor: 'default', paddingLeft: '1rem' }}
        color={changeTextColor}
        fontWeight={columnMeta.id === "discount_options.percentage" ? 'bold' : null}
        textDecoration={columnMeta.id === "calculated_price" ? 'underline' : null}
        textDecorationColor={columnMeta.id === "calculated_price" ? '#dd6b20' : null}
    />
}