import { Flex, useColorModeValue, VStack, useSteps, HStack, Button } from "@chakra-ui/react"
import MyStepper from '../../components/MyStepper'
import InitializePDF from "../../components/CreatePDF/InitializePDF"
import CustomizePDF from "../../components/CreatePDF/CustomizePDF"
import FinalizePDF from "../../components/CreatePDF/FinalizePDF"
import CompletePDF from "../../components/CreatePDF/CompletePDF"
import ItemizePDF from "../../components/CreatePDF/ItemizePDF"
import RevisePDF from "../../components/CreatePDF/RevisePDF"
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi'
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { fullReset } from "../../redux/CreateDoc/createDocSlice"
import { useState } from "react"

export default function EditInvoice() {
    const { contact } = useSelector((state) => state.createDoc)
    const { products } = useSelector((state) => state.createDoc)
    const { error } = useSelector((state) => state.createDoc)
    const { edit_mode } = useSelector((state) => state.createDoc)
    const { shipping_content_incomplete } = useSelector((state) => state.createDoc)
    const [columns, setColumns] = useState([])
    const dispatch = useDispatch()

    // HR - provide name and description for steps to populate text in the stepper component
    const steps = [
        { title: 'Initialize', description: 'Enter Customer Info' },
        { title: 'Itemize', description: 'Select Products' },
        { title: 'Revise', description: 'Rearrange Tables' },
        { title: 'Customize', description: 'Style PDF' },
        { title: 'Finalize', description: 'Review PDF' },
    ]

    // HR - state to track our current step
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    function incrementStep() {
        switch (activeStep) {
            case 0:
                // HR - block the user from proceeding to the next step until a contact is selected
                if (Object.keys(contact).length === 0) {
                    toast.error("Please select a contact to continue!")
                } else if (shipping_content_incomplete) {
                    toast.error("Please specify shipping information!")
                } else {
                    setActiveStep(activeStep + 1)
                }
                break;
            case 1:
                // HR - block the user from proceeding to the next step until a product is selected
                if (products.length > 0) {
                    setActiveStep(activeStep + 1)
                } else {
                    toast.error("Please select a product to continue!")
                }
                break;
            case 4:
                if (!error) {
                    setActiveStep(activeStep + 1)
                    dispatch(fullReset())
                }
                break;

            default:
                setActiveStep(activeStep + 1)
                break;
        }
    }

    function decrementStep() {
        if (activeStep !== 0) {
            setActiveStep(activeStep - 1)
        }
    }

    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
            pt={10}
            pb={10}
        >
            <VStack spacing={10} w={'100%'}>
                <MyStepper activeStep={activeStep} steps={steps} />
                <HStack spacing={"10rem"}>
                    {/* HR - dynamically display previous/next arrows depending on the current step */}
                    {activeStep > 0 && activeStep < 5 && <Button variant={"outline"} colorScheme={"blue"} onClick={decrementStep} leftIcon={<FiArrowLeft style={{ fontSize: "1.5rem" }} />}>Back</Button>}
                    {activeStep < 4 && <Button variant={"solid"} colorScheme="blue" onClick={incrementStep} rightIcon={<FiArrowRight style={{ fontSize: "1.5rem" }} />}>Next</Button>}
                </HStack>
                {/* HR - dyanamically display components depending on current step */}
                {activeStep === 0 && <InitializePDF type="Invoice" mode={edit_mode} />}
                {activeStep === 1 && <ItemizePDF type="Invoice" mode={edit_mode} columns={columns} setColumns={setColumns} />}
                {activeStep === 2 && <RevisePDF type="Invoice" mode={edit_mode} />}
                {activeStep === 3 && <CustomizePDF type="Invoice" mode={edit_mode} />}
                {activeStep === 4 && <FinalizePDF type="Invoice" incrementStep={incrementStep} mode={edit_mode} />}
                {activeStep === 5 && <CompletePDF type="Invoice" mode={edit_mode} />}
            </VStack>
        </Flex>
    )
}