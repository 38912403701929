import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    Container,
    Divider,
    HStack,
    Switch,
    ModalOverlay,
    Tooltip
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default function EditGlobalDiscountPopup({ isOpen, onClose, globalDiscountOptions, setGlobalDiscountOptions, handleGlobalDiscountChange }) {
    const {global_discount_options} = useSelector((state) => state.createDoc)

    // HR - set the popup defaults to the current redux state of global_discount_options
    useEffect(() => {
        setGlobalDiscountOptions(global_discount_options)
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            style={{ zIndex: "--chakra-sizes-container-md" }}
            size={"md"}
            scrollBehavior='outside'
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent>
                <Container centerContent>
                    <ModalHeader>Edit Global Discount</ModalHeader>
                    <Divider />
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <VStack spacing={'1.5rem'}>
                            <HStack justifyContent={'center'}>
                                <FormLabel>{globalDiscountOptions.use_cost ? 'Cost' : 'List Price'}</FormLabel>
                                <Switch size={'lg'} variant={"totalStyle"} isChecked={globalDiscountOptions.use_cost} onChange={(e) => setGlobalDiscountOptions({ ...globalDiscountOptions, use_cost: e.target.checked })} />
                            </HStack>
                            <HStack justifyContent={'center'}>
                                <FormLabel>{globalDiscountOptions.use_markup ? 'Markup' : 'Discount'}</FormLabel>
                                <Switch size={'lg'} variant={"totalStyle"} isChecked={globalDiscountOptions.use_markup} onChange={(e) => setGlobalDiscountOptions({ ...globalDiscountOptions, use_markup: e.target.checked })} />
                            </HStack>
                            <FormControl>
                                <VStack>
                                    <FormLabel m={0} fontSize={'md'}>
                                        {globalDiscountOptions.use_markup ? 'Global Markup (%)' : 'Global Discount (%)'}
                                    </FormLabel>
                                    <Input type={"number"} value={globalDiscountOptions.percentage} onChange={(e) => setGlobalDiscountOptions({ ...globalDiscountOptions, percentage: e.target.value })} w={'5vw'} />
                                </VStack>
                            </FormControl>
                            <Tooltip label="This will reset ALL rows to a standard configuration">
                                <Button colorScheme={'orange'} onClick={handleGlobalDiscountChange}>Apply Changes</Button>
                            </Tooltip>
                        </VStack>
                    </ModalBody>
                </Container>
            </ModalContent>
        </Modal>
    )
}