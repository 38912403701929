const quoteHeader = {
    id: "5df3180a09ea16dc4b95f911",
    items: [
        {
            sr: 0,
            product_id: "Id",
            name: "Name",
            description: "Description",
            quantity: "Qt.",
            list_price: "Price",
            total: "Total",
        },
    ],
};

export default quoteHeader;
