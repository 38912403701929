import { HStack, IconButton, Tooltip } from "@chakra-ui/react"
import { FiExternalLink, FiThumbsDown, FiThumbsUp } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { editStart } from "../../redux/CreateDoc/createDocSlice"
import { useNavigate } from 'react-router-dom'
import { updateDocument, getDocuments } from "../../redux/Document/documentCalls"

export default function OpenCell({ row, table }) {
    const meta = table.options.meta
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { account_id } = useSelector((state) => state.account)

    function openDocument() {
        dispatch(editStart(row.original))
        navigate('/edit/options')
    }

    function handleToggleStatus(status) {
        const editPayload = { quote_status: status, doc_number: row.original.doc_number }
        updateDocument(editPayload, dispatch)
        getDocuments(account_id, dispatch)
    }

    return (
        <>
            <HStack spacing={'1rem'}>
                <Tooltip label='Open' fontSize='sm'>
                    <IconButton onClick={openDocument} variant={"outline"} icon={<FiExternalLink />} />
                </Tooltip>
                {/* HR - provide quotes with buttons to toggle status as active or inactive*/}
                {(row.original.type === "Quote" && row.original.quote_status === 'Active' && row.original.is_complete) &&
                    <Tooltip label='Make Inactive' fontSize='sm'>
                        <IconButton onClick={() => handleToggleStatus('Inactive')} variant={"outline"} borderColor={"rgb(187, 134, 252)"} icon={<FiThumbsDown color="rgb(187, 134, 252)" />} />
                    </Tooltip>
                }
                {(row.original.type === "Quote" && row.original.quote_status !== 'Active' && row.original.is_complete) &&
                    <Tooltip label='Make Active' fontSize='sm'>
                        <IconButton onClick={() => handleToggleStatus('Active')} variant={"outline"} borderColor={"rgb(56, 161, 105)"} icon={<FiThumbsUp color="rgb(56, 161, 105)" />} />
                    </Tooltip>
                }
            </HStack>
        </>
    )
}
