import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        // alignItems: "center",
        padding: 5,
        marginVertical: 5
    },
    termTitle: {
        width: "40%",
        fontSize: 12,
        fontFamily: 'Times-Roman',
        marginHorizontal: 2
    },
    termDetails: {
        width: "50%",
        fontSize: 12,
        fontFamily: 'Times-Roman',
        marginHorizontal: 2
    },
});

// HR this maps item data (or headers) into a row of text
export default function TermsTableRow({ termTitle, termDetails }) {
    return (
        <View style={styles.row}>
            <Text style={styles.termTitle}>{termTitle}</Text>
            <Text style={styles.termDetails}>{termDetails}</Text>
        </View>
    )
};
