import axios from 'axios'
import {toast} from 'react-toastify'
import * as actions from "./userSlice"
import getApiUri from '../../helper/getApiUri'
import { updateNameSuccess } from '../Auth/authSlice'

const apiUrl = getApiUri() + "/users"

export const createUser = async (payload, dispatch) => {
    dispatch(actions.createUserStart())
    try {
        const {data} = await axios.post(apiUrl, payload)
        dispatch(actions.createUserSuccess(data.data))
        toast.success(data.message);
        return true
    } catch (error) {
        dispatch(actions.createUserFailure())
        return false
    }
}

export const getUsers = async (id, dispatch) => {
    dispatch(actions.getUsersStart())
    try {
        const {data} = await axios.get(apiUrl + "/" + id)
        dispatch(actions.getUsersSuccess(data.data))
        return true
    } catch (error) {
        dispatch(actions.getUsersFailure())
        return false
    }
}

export const updateUser = async (payload, dispatch) => {
    dispatch(actions.updateUserStart())
    try {
        const url = apiUrl + '/' + payload.id
        const {data} = await axios.put(url, payload)
        dispatch(actions.updateUserSuccess(data.data))
        toast.success(data.message)
        if(payload.display_name){
            dispatch(updateNameSuccess(data.data))
        }
        return true
    } catch (error) {
        console.log(error)
        dispatch(actions.updateUserFailure())
        return false
    }
}

export const deleteUser = async (id, dispatch) => {
    dispatch(actions.deleteUserStart())
    try {
        const {data} = await axios.delete(apiUrl + "/" + id)
        dispatch(actions.deleteUserSuccess(id))
        toast.success(data.message)
        return true
    } catch (error) {
        toast.error("Something went wrong!");
        dispatch(actions.deleteUserFailure())
        return false
    }
}