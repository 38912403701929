import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalOverlay,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    Select,
    HStack,
    RadioGroup,
    Radio,
    Stack
} from '@chakra-ui/react'
import { createContact } from '../redux/Contact/contactCalls'
import { useDispatch, useSelector } from 'react-redux';
import handleInputChange from '../helper/inputChange';
import { useState, useEffect } from 'react';

export default function AddContactPopup({ isOpen, onClose }) {
    const dispatch = useDispatch();
    const { account_id } = useSelector((state) => state.account)
    const [newContact, setNewContact] = useState({
        company_name: "",
        name: "",
        phone: "",
        email: "",
        type: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        account_id: account_id
    })

    function handleAddContact(e) {
        e.preventDefault()
        if (createContact.name !== "") {
            createContact(newContact, dispatch)
        }
        onClose()
        setNewContact({
            company_name: "",
            name: "",
            phone: "",
            email: "",
            type: "",
            address_1: "",
            address_2: "",
            city: "",
            state: "",
            country: "",
            zip: "",
            account_id: account_id
        })
    }

    function inputChange(e) {
        handleInputChange(e.target, setNewContact)
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                style={{ zIndex: "--chakra-sizes-container-md" }}
                size={"md"}
                scrollBehavior='outside'
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Contact</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form onSubmit={handleAddContact}>
                            <VStack spacing={"1rem"}>
                                <FormControl isRequired>
                                    <FormLabel>Company Name</FormLabel>
                                    <Input placeholder='Company Name' name="company_name" value={newContact.company_name} onChange={inputChange} />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Contact Name</FormLabel>
                                    <Input placeholder='Contact Name' name="name" value={newContact.name} onChange={inputChange} />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Type</FormLabel>
                                    <Select placeholder='Select type' onChange={inputChange} name='type' value={newContact.type}>
                                        <option value='customer'>Customer</option>
                                        <option value='vendor'>Vendor</option>
                                    </Select>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Email</FormLabel>
                                    <Input placeholder='Contact Email' name="email" value={newContact.email} onChange={inputChange} />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Phone</FormLabel>
                                    <Input placeholder='Contact Phone Number' type="string" name="phone" value={newContact.phone} onChange={inputChange} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Address <span style={{ color: "red" }}>*</span></FormLabel>
                                    <Input
                                        name="address_1" data-type="address" placeholder="Street Address" type="text" mb={4}
                                        value={newContact.address_1} onChange={inputChange} isRequired
                                    />
                                    <Input
                                        name="address_2" data-type="address" placeholder="Apartment number" type="text" mb={4}
                                        value={newContact.address_2} onChange={inputChange}
                                    />
                                    <Input
                                        name="city" data-type="address" placeholder="City" type="text" mb={4}
                                        value={newContact.city} onChange={inputChange} isRequired
                                    />
                                    <Input
                                        name="state" data-type="address" placeholder="State" type="text" mb={4}
                                        value={newContact.state} onChange={inputChange} isRequired
                                    />
                                    <Input
                                        name="country" data-type="address" placeholder="Country" type="text" mb={4}
                                        value={newContact.country} onChange={inputChange} isRequired
                                    />
                                    <Input
                                        name="zip" data-type="address" placeholder="Zipcode" type="text"
                                        value={newContact.zip} onChange={inputChange} isRequired
                                    />
                                </FormControl>
                                <HStack>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <Button colorScheme='blue' my={3} mx={5} type="submit">
                                        Save
                                    </Button>
                                </HStack>
                            </VStack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}