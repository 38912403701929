import { createSlice } from "@reduxjs/toolkit";

export const documentSlice = createSlice({
    name: "documents",
    initialState: {
        documents: [],
        quotes: [],
        purchase_orders: [],
        invoices: [],
        drafts: [],
        archived_documents: [],
        createDocumentProgress: false,
        getDocumentsProgress: false,
        updateDocumentProgress: false,
        deleteDocumentProgress: false,
        getLastDocumentProgress: false,
        error: false,
    },
    reducers: {
        // create document
        createDocumentStart: (state) => {
            state.createDocumentProgress = true
        },
        createDocumentSuccess: (state, action) => {
            state.error = false
            state.documents.push(action.payload)
            state.createDocumentProgress = false
        },
        createDocumentFailure: (state) => {
            state.error = true
            state.createDocumentProgress = false
        },

        // get document
        getDocumentsStart: (state) => {
            state.getDocumentsProgress = true
        },
        getDocumentsSuccess: (state, action) => {
            state.error = false
            state.documents = action.payload.documents
            state.quotes = action.payload.quotes
            state.purchase_orders = action.payload.purchase_orders
            state.invoices = action.payload.invoices
            state.drafts = action.payload.drafts
            state.archived_documents = action.payload.archived_documents
            state.getDocumentsProgress = false
        },
        getDocumentsFailure: (state) => {
            state.error = true
            state.getDocumentsProgress = false
        },

        // edit document
        updateDocumentStart: (state) => {
            state.updateDocumentProgress = true
        },
        updateDocumentSuccess: (state, action) => {
            state.error = false
            state.documents.push(action.payload)
            state.updateDocumentProgress = false
        },
        updateDocumentFailure: (state) => {
            state.error = true
            state.updateDocumentProgress = false
        },

        // get document
        deleteDocumentStart: (state) => {
            state.deleteDocumentProgress = true
        },
        deleteDocumentSuccess: (state, action) => {
            state.error = false
            state.documents = state.documents.filter(
                (document) => document._id !== action.payload
            )
            state.deleteDocumentProgress = false
        },
        deleteDocumentFailure: (state) => {
            state.error = true
            state.deleteDocumentProgress = false
        },

        // get last document
        getLastDocumentStart: (state) => {
            state.getLastDocumentProgress = true
        },
        getLastDocumentSuccess: (state, action) => {
            state.error = false
            state.getDocumentgetLastDocumentProgresssProgress = false
        },
        getLastDocumentFailure: (state) => {
            state.error = true
            state.getLastDocumentProgress = false
        },

    },
})

export const {
    createDocumentStart,
    createDocumentSuccess,
    createDocumentFailure,
    getDocumentsStart,
    getDocumentsSuccess,
    getDocumentsFailure,
    updateDocumentStart,
    updateDocumentSuccess,
    updateDocumentFailure,
    deleteDocumentStart,
    deleteDocumentSuccess,
    deleteDocumentFailure,
    getLastDocumentStart,
    getLastDocumentSuccess,
    getLastDocumentFailure,
} = documentSlice.actions

export default documentSlice.reducer