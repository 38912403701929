import {
    Text,
    Image,
    View
} from '@react-pdf/renderer';
import { textStyles, termsStyles } from "../../styles/PDFStyles"
import TermsTable from './Terms-Table/TermsTable';


const TermsPage1 = ({ user_name, account_name, terms_content, default_terms_text }) => {
    return (
        <View style={termsStyles["Terms Container"]} break>
            <Text style={termsStyles["Terms Header Text"]}>Terms and Conditions</Text>

            {/* HR - TermsTable is used to correctly format terms & conditions info */}
            <TermsTable fob={terms_content.fob} payment={terms_content.payment} delivery={terms_content.delivery} />

            <View style={termsStyles['Terms Section']}>
                <Text style={textStyles["Italic Text"]}>{default_terms_text.text_1}</Text>
            </View>
            <View style={termsStyles['Terms Section']}>
                <Text style={textStyles["Default Text"]}>{default_terms_text.text_2}</Text>
            </View>
            <View style={termsStyles['Terms Section']}>
                <Text style={termsStyles["Terms Footer Text"]}>Best regards,</Text>
                <Text style={termsStyles["Terms Footer Text"]}>{user_name}</Text>
                <Text style={termsStyles["Terms Footer Text"]}>{account_name}</Text>
            </View>
        </View>
    )
}

export default TermsPage1;