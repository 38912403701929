import React, { useCallback, useEffect, useRef, useState } from "react"
import { createPortal, unstable_batchedUpdates } from "react-dom"
import {
    closestCenter,
    pointerWithin,
    rectIntersection,
    DndContext,
    DragOverlay,
    getFirstCollision,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useDroppable,
    useSensors,
    useSensor,
    MeasuringStrategy,
    defaultDropAnimationSideEffects
} from "@dnd-kit/core"
import {
    SortableContext,
    useSortable,
    arrayMove,
    defaultAnimateLayoutChanges,
    verticalListSortingStrategy,
    horizontalListSortingStrategy
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { coordinateGetter as multipleContainerCoordinateGetter } from "../../helper/coordinateGetter"
import { DNDItem } from "./DNDItem"
import { DNDContainer } from "./DNDContainer"
import { GrTableAdd } from "react-icons/gr";
import { toast } from 'react-toastify'
import { reorderSuccess } from "../../redux/CreateDoc/createDocSlice"
import { useDispatch } from "react-redux"
import { Box, Grid } from "@chakra-ui/react"

const animateLayoutChanges = args =>
    defaultAnimateLayoutChanges({ ...args, wasDragging: true })

function DroppableContainer({
    children,
    tables = 1,
    disabled,
    id,
    items,
    style,
    ...props
}) {
    const {
        active,
        attributes,
        isDragging,
        listeners,
        over,
        setNodeRef,
        transition,
        transform
    } = useSortable({
        id,
        data: {
            type: "container",
            children: items
        },
        animateLayoutChanges
    })
    const isOverContainer = over
        ? (id === over.id && active?.data.current?.type !== "container") ||
        items.includes(over.id)
        : false

    return (
        <DNDContainer
            ref={disabled ? undefined : setNodeRef}
            style={{
                ...style,
                transition,
                transform: CSS.Translate.toString(transform),
                opacity: isDragging ? 0.5 : undefined
            }}
            hover={isOverContainer}
            handleProps={{
                ...attributes,
                ...listeners
            }}
            tables={tables}
            {...props}
        >
            {children}
        </DNDContainer>
    )
}

const dropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: "0.5"
            }
        }
    })
}

export const TRASH_ID = "void"
const PLACEHOLDER_ID = "placeholder"
const empty = []

export default function MultipleContainers({
    adjustScale = false,
    cancelDrop,
    tables,
    handle = false,
    initialItems,
    initialTables,
    containerStyle,
    coordinateGetter = multipleContainerCoordinateGetter,
    getItemStyles = () => ({}),
    wrapperStyle = () => ({}),
    minimal = false,
    modifiers,
    renderItem,
    strategy = verticalListSortingStrategy,
    trashable = false,
    vertical = false,
    scrollable
}) {
    var initialTableLength = Object.values(initialTables).flat().length
    const [items, setItems] = useState((Object.keys(initialTables).length > 0 && initialTableLength === initialItems.length) ? initialTables : { A: initialItems })
    const [containers, setContainers] = useState(Object.keys(items))
    const [activeId, setActiveId] = useState(null)
    const lastOverId = useRef(null)
    const recentlyMovedToNewContainer = useRef(false)
    const isSortingContainer = activeId ? containers.includes(activeId) : false
    const dispatch = useDispatch()

    /**
     * Custom collision detection strategy optimized for multiple containers
     *
     * - First, find any droppable containers intersecting with the pointer.
     * - If there are none, find intersecting containers with the active draggable.
     * - If there are no intersecting containers, return the last matched intersection
     *
     */
    const collisionDetectionStrategy = useCallback(
        args => {
            if (activeId && activeId in items) {
                return closestCenter({
                    ...args,
                    droppableContainers: args.droppableContainers.filter(
                        container => container.id in items
                    )
                })
            }

            // Start by finding any intersecting droppable
            const pointerIntersections = pointerWithin(args)
            const intersections =
                pointerIntersections.length > 0
                    ? // If there are droppables intersecting with the pointer, return those
                    pointerIntersections
                    : rectIntersection(args)
            let overId = getFirstCollision(intersections, "id")

            if (overId != null) {
                if (overId === TRASH_ID) {
                    // If the intersecting droppable is the trash, return early
                    // Remove this if you're not using trashable functionality in your app
                    return intersections
                }

                if (overId in items) {
                    const containerItems = items[overId]

                    // If a container is matched and it contains items (tables 'A', 'B', 'C')
                    if (containerItems.length > 0) {
                        // Return the closest droppable within that container
                        overId = closestCenter({
                            ...args,
                            droppableContainers: args.droppableContainers.filter(
                                container =>
                                    container.id !== overId &&
                                    containerItems.includes(container.id)
                            )
                        })[0]?.id
                    }
                }

                lastOverId.current = overId

                return [{ id: overId }]
            }

            // When a draggable item moves to a new container, the layout may shift
            // and the `overId` may become `null`. We manually set the cached `lastOverId`
            // to the id of the draggable item that was moved to the new container, otherwise
            // the previous `overId` will be returned which can cause items to incorrectly shift positions
            if (recentlyMovedToNewContainer.current) {
                lastOverId.current = activeId
            }

            // If no droppable is matched, return the last match
            return lastOverId.current ? [{ id: lastOverId.current }] : []
        },
        [activeId, items]
    )
    const [clonedItems, setClonedItems] = useState(null)
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter
        })
    )
    const findContainer = id => {
        if (id in items) {
            return id
        }

        return Object.keys(items).find(key => items[key].includes(id))
    }

    const getIndex = id => {
        const container = findContainer(id)

        if (!container) {
            return -1
        }

        const index = items[container].indexOf(id)

        return index
    }

    const onDragCancel = () => {
        if (clonedItems) {
            // Reset items to their original state in case items have been
            // Dragged across containers
            setItems(clonedItems)
        }

        setActiveId(null)
        setClonedItems(null)
    }

    useEffect(() => {
        requestAnimationFrame(() => {
            recentlyMovedToNewContainer.current = false
        })
        dispatch(reorderSuccess(items))
    }, [items])

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={collisionDetectionStrategy}
            measuring={{
                droppable: {
                    strategy: MeasuringStrategy.Always
                }
            }}
            onDragStart={({ active }) => {
                setActiveId(active.id)
                setClonedItems(items)
            }}
            onDragOver={({ active, over }) => {
                const overId = over?.id

                if (overId == null || overId === TRASH_ID || active.id in items) {
                    return
                }

                const overContainer = findContainer(overId)
                const activeContainer = findContainer(active.id)

                if (!overContainer || !activeContainer) {
                    return
                }

                if (activeContainer !== overContainer) {
                    setItems(items => {
                        const activeItems = items[activeContainer]
                        const overItems = items[overContainer]
                        const overIndex = overItems.indexOf(overId)
                        const activeIndex = activeItems.indexOf(active.id)

                        let newIndex

                        if (overId in items) {
                            newIndex = overItems.length + 1
                        } else {
                            const isBelowOverItem =
                                over &&
                                active.rect.current.translated &&
                                active.rect.current.translated.top >
                                over.rect.top + over.rect.height

                            const modifier = isBelowOverItem ? 1 : 0

                            newIndex =
                                overIndex >= 0 ? overIndex + modifier : overItems.length + 1
                        }

                        recentlyMovedToNewContainer.current = true

                        return {
                            ...items,
                            [activeContainer]: items[activeContainer].filter(
                                item => item !== active.id
                            ),
                            [overContainer]: [
                                ...items[overContainer].slice(0, newIndex),
                                items[activeContainer][activeIndex],
                                ...items[overContainer].slice(
                                    newIndex,
                                    items[overContainer].length
                                )
                            ]
                        }
                    })
                }
            }}
            onDragEnd={({ active, over }) => {
                if (active.id in items && over?.id) {
                    setContainers(containers => {
                        const activeIndex = containers.indexOf(active.id)
                        const overIndex = containers.indexOf(over.id)

                        return arrayMove(containers, activeIndex, overIndex)
                    })
                }

                const activeContainer = findContainer(active.id)

                if (!activeContainer) {
                    setActiveId(null)
                    return
                }

                const overId = over?.id

                if (overId == null) {
                    setActiveId(null)
                    return
                }

                if (overId === TRASH_ID) {
                    setItems(items => ({
                        ...items,
                        [activeContainer]: items[activeContainer].filter(
                            id => id !== activeId
                        )
                    }))
                    setActiveId(null)
                    return
                }

                if (overId === PLACEHOLDER_ID) {
                    const newContainerId = getNextContainerId()

                    unstable_batchedUpdates(() => {
                        setContainers(containers => [...containers, newContainerId])
                        setItems(items => ({
                            ...items,
                            [activeContainer]: items[activeContainer].filter(
                                id => id !== activeId
                            ),
                            [newContainerId]: [active.id]
                        }))
                        setActiveId(null)
                    })
                    return
                }

                const overContainer = findContainer(overId)

                if (overContainer) {
                    const activeIndex = items[activeContainer].indexOf(active.id)
                    const overIndex = items[overContainer].indexOf(overId)

                    if (activeIndex !== overIndex) {
                        setItems(items => ({
                            ...items,
                            [overContainer]: arrayMove(
                                items[overContainer],
                                activeIndex,
                                overIndex
                            )
                        }))
                    }
                }

                setActiveId(null)
            }}
            cancelDrop={cancelDrop}
            onDragCancel={onDragCancel}
            modifiers={modifiers}
        >
            <Grid
                templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
            >
                <SortableContext
                    items={[...containers, PLACEHOLDER_ID]}
                    strategy={
                        vertical
                            ? verticalListSortingStrategy
                            : horizontalListSortingStrategy
                    }
                >
                    {containers.map(containerId => (
                        <DroppableContainer
                            key={containerId}
                            id={containerId}
                            label={minimal ? undefined : `Table ${containerId}`}
                            tables={tables}
                            items={items[containerId]}
                            scrollable={scrollable}
                            style={containerStyle}
                            unstyled={minimal}
                            onRemove={() => handleRemove(containerId)}
                        >
                            <SortableContext items={items[containerId]} strategy={strategy}>
                                {items[containerId].map((value, index) => {
                                    return (
                                        <SortableItem
                                            disabled={isSortingContainer}
                                            key={value}
                                            id={value}
                                            index={index}
                                            handle={handle}
                                            style={getItemStyles}
                                            wrapperStyle={wrapperStyle}
                                            renderItem={renderItem}
                                            containerId={containerId}
                                            getIndex={getIndex}
                                        />
                                    )
                                })}
                            </SortableContext>
                        </DroppableContainer>
                    ))}
                    {minimal ? (
                        undefined
                    ) : (
                        <DroppableContainer
                            id={PLACEHOLDER_ID}
                            disabled={isSortingContainer}
                            items={empty}
                            onClick={handleAddTable}
                            placeholder
                        >
                            <GrTableAdd style={{ fontSize: "1.5rem" }} /> Add Table
                        </DroppableContainer>
                    )}
                </SortableContext>
            </Grid>
            {createPortal(
                <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
                    {activeId
                        ? containers.includes(activeId)
                            ? renderContainerDragOverlay(activeId)
                            : renderSortableItemDragOverlay(activeId)
                        : null}
                </DragOverlay>,
                document.body
            )}
            {trashable && activeId && !containers.includes(activeId) ? (
                <Trash id={TRASH_ID} />
            ) : null}
        </DndContext>
    )

    function renderSortableItemDragOverlay(id) {
        return (
            <DNDItem
                value={id}
                handle={handle}
                style={getItemStyles({
                    containerId: findContainer(id),
                    overIndex: -1,
                    index: getIndex(id),
                    value: id,
                    isSorting: true,
                    isDragging: true,
                    isDragOverlay: true
                })}
                // color={getColor(id)}
                wrapperStyle={wrapperStyle({ index: 0 })}
                renderItem={renderItem}
                dragOverlay
            />
        )
    }

    function renderContainerDragOverlay(containerId) {
        return (
            <DNDContainer
                label={`Table ${containerId}`}
                tables={tables}
                style={{
                    height: "100%"
                }}
                shadow
                unstyled={false}
            >
                {items[containerId].map((item, index) => (
                    <DNDItem
                        key={item}
                        value={item}
                        handle={handle}
                        style={getItemStyles({
                            containerId,
                            overIndex: -1,
                            index: getIndex(item),
                            value: item,
                            isDragging: false,
                            isSorting: false,
                            isDragOverlay: false
                        })}
                        // color={getColor(item)}
                        wrapperStyle={wrapperStyle({ index })}
                        renderItem={renderItem}
                    />
                ))}
            </DNDContainer>
        )
    }

    function handleRemove(containerID) {
        const moveItems = items[containerID]
        var containerIndex = containers.indexOf(containerID)
        var itemsCopy = { ...items }
        var itemsArrayCopy = []
        // HR - make sure table cannot be deleted if it's the only one
        if (containers.length > 1) {
            // HR - check if container has any items
            if (moveItems) {
                if(containerIndex === 0){
                    itemsArrayCopy = [...items[containers[1]]]
                }else{
                    itemsArrayCopy = [...items[containers[containerIndex - 1]]]
                }
                moveItems.forEach(item => {
                    // HR - if deleting the first table, move items to the next table (note working)
                    if (containerIndex === 0) {
                        itemsArrayCopy.push(item)
                        itemsCopy[containers[1]] = itemsArrayCopy
                    } else { // HR - if deleting a subsequent table, move items to table before  (working)
                        itemsArrayCopy.push(item)
                        itemsCopy[containers[containerIndex - 1]] = itemsArrayCopy
                    }
                })
            }
            // HR - update containers array and items object to no longer include removed container
            setContainers(containers => containers.filter(id => id !== containerID))
            delete itemsCopy[containerID]
            setItems(itemsCopy)
        } else {
            toast.error("You must have at least 1 table!")
        }
    }

    function handleAddTable() {
        const newContainerId = getNextContainerId()

        unstable_batchedUpdates(() => {
            setContainers(containers => [...containers, newContainerId])
            setItems(items => ({
                ...items,
                [newContainerId]: []
            }))
        })
    }

    function getNextContainerId() {
        const containerIds = Object.keys(items)
        const lastContainerId = containerIds[containerIds.length - 1]

        return String.fromCharCode(lastContainerId.charCodeAt(0) + 1)
    }
}

// function getColor(id) {
//     switch (String(id)[0]) {
//         case "A":
//             return "#7193f1"
//         case "B":
//             return "#ffda6c"
//         case "C":
//             return "#00bcd4"
//         case "D":
//             return "#ef769f"
//     }

//     return undefined
// }

function Trash({ id }) {
    const { setNodeRef, isOver } = useDroppable({
        id
    })

    return (
        <Box 
            ref={setNodeRef}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                left: "50%",
                marginLeft: -150,
                bottom: 20,
                width: 300,
                height: 60,
                borderRadius: 5,
                border: "1px solid",
                borderColor: isOver ? "red" : "#DDD"
            }}
        >
            Drop here to delete
        </Box>
    )
}

function SortableItem({
    disabled,
    id,
    index,
    handle,
    renderItem,
    style,
    containerId,
    getIndex,
    wrapperStyle
}) {
    const {
        setNodeRef,
        setActivatorNodeRef,
        listeners,
        isDragging,
        isSorting,
        over,
        overIndex,
        transform,
        transition
    } = useSortable({
        id
    })
    const mounted = useMountStatus()
    const mountedWhileDragging = isDragging && !mounted

    return (
        <DNDItem
            ref={disabled ? undefined : setNodeRef}
            value={id}
            dragging={isDragging}
            sorting={isSorting}
            handle={handle}
            handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
            index={index}
            wrapperStyle={wrapperStyle({ index })}
            style={style({
                index,
                value: id,
                isDragging,
                isSorting,
                overIndex: over ? getIndex(over.id) : overIndex,
                containerId
            })}
            // color={getColor(id)}
            transition={transition}
            transform={transform}
            fadeIn={mountedWhileDragging}
            listeners={listeners}
            renderItem={renderItem}
        />
    )
}

function useMountStatus() {
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => setIsMounted(true), 500)

        return () => clearTimeout(timeout)
    }, [])

    return isMounted
}
