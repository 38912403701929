import { Container, Stack, Heading, Text, Button, Flex, Image, Center } from "@chakra-ui/react"
import notFoundImage from "../images/error_404.png"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { createPortal } from "../redux/Account/accountCalls";

export default function NotFound() {
    const navigate = useNavigate()
    const { user_id } = useSelector((state) => state.auth)
    const { account_id } = useSelector((state) => state.account)
    const { status } = useSelector((state) => state.account.subscription)

      // HR - get portal url and open in new tab
      async function handleManageBilling() {
        var url = await createPortal({ account_id })
        var win = window.open(url, '_blank')
        win.focus()
    }


    return (
        <Container maxW={'5xl'}>
            <Stack
                textAlign={'center'}
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 20, md: 28 }}>
                <Heading
                    fontWeight={600}
                    fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                    lineHeight={'110%'}>
                    Page{' '}
                    <Text as={'span'} color={'blue.400'}>
                        not found
                    </Text>
                </Heading>

                {/* HR - if user is logged in and subscription expired, change message to alert them to manage billing */}
                {(user_id && status === "inactive") ?
                    <Text color={'gray.500'} maxW={'3xl'}>
                        Your current subscription status does not allow you to access this page. To update your billing information or manage your subscription, please click the button below.
                    </Text>
                    :
                    <Text color={'gray.500'} maxW={'3xl'}>
                        We could not find the page you were looking for. It either does not exist OR you do not currently have access to its content.
                    </Text>
                }

                <Stack spacing={6} direction={'row'}>
                    {/* HR - if user logged in and subscription expired, provide button to stripe portal */}
                    {(user_id && status === "inactive") ?
                        <Button
                            rounded={'full'}
                            px={6}
                            colorScheme={'blue'}
                            bg={'blue.400'}
                            onClick={handleManageBilling}
                            _hover={{ bg: 'orange.500' }}>
                            Manage Billing
                        </Button>
                        :
                        <Button
                            rounded={'full'}
                            px={6}
                            colorScheme={'blue'}
                            bg={'blue.400'}
                            onClick={() => navigate("/")}
                            _hover={{ bg: 'orange.500' }}>
                            Return Home
                        </Button>
                    }
                </Stack>
                <Center>
                    <Image src={notFoundImage} />
                </Center>
            </Stack>
        </Container>
    );

}