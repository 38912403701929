import {toast} from 'react-toastify'
import * as actions from "./inviteUserSlice"
import axios from 'axios'
import getApiUri from '../../helper/getApiUri'

const apiUrl = getApiUri() + "/inviteUsers"

export const addInviteUser = async (payload, dispatch) => {
    dispatch(actions.addInviteUserStart())
    try {
        const {data} = await axios.post(apiUrl, payload)
        dispatch(actions.addInviteUserSuccess(data.data))
        toast.success(data.message)
        return true
    } catch (error) {
        dispatch(actions.addInviteUserFailure())
        toast.error(error.response.data.message);
        return false
    }
}

export const getInviteUsers = async (payload, dispatch) => {
    dispatch(actions.getInviteUsersStart())
    try {
        const {data} = await axios.get(apiUrl + `/${payload}`)
        dispatch(actions.getInviteUsersSuccess(data.data))
        return true
    } catch (error) {
        dispatch(actions.getInviteUsersFailure())
        toast.error(error.response.data.message);
        return false
    }
}