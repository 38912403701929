import { useDispatch, useSelector } from 'react-redux'
import { HStack, VStack, Switch, FormControl, FormLabel, Text, Tooltip } from '@chakra-ui/react'
import MultipleContainers from '../MultipleTableDND/MultipleContainers'
import { useEffect, useState } from 'react'
import { FiInfo } from 'react-icons/fi'
import { toggleTotalCaclulation, updateShowDescription } from '../../redux/CreateDoc/createDocSlice'
export default function RevisePDF() {
    const { products } = useSelector((state) => state.createDoc)
    const { tables } = useSelector((state) => state.createDoc)
    const { total } = useSelector((state) => state.createDoc)
    const productIds = products?.map(({ product_id, name }) => name + ":::(" + product_id + ")")
    const [totalIsItemized, setTotalIsItemized] = useState(false)
    const [showDescription, setShowDescription] = useState(true)
    const dispatch = useDispatch()

    // HR - if editing document (from history or moving back to previous screen during creation), opulate switch value with saved redux state data
    useEffect(() => {
        if (total) {
            setTotalIsItemized(total.total_calculation === "itemized")
        }
    }, [])

    useEffect(() => {
        dispatch(toggleTotalCaclulation(totalIsItemized))
    }, [totalIsItemized])


    useEffect(() => {
        dispatch(updateShowDescription(showDescription))
    }, [showDescription])

    return (
        <VStack spacing={'1rem'}>
            <HStack>
                <HStack>
                    <Text fontSize={'medium'}>How do you want your total(s) calculated?</Text>
                    <Tooltip label='Grand = 1 total, Itemized = 1 total per table' fontSize='sm' placement="right-start">
                        <span><FiInfo /></span>
                    </Tooltip>
                </HStack>
            </HStack>
            <FormControl >
                <HStack justifyContent={'center'}>
                    <FormLabel>{totalIsItemized ? 'Itemized totals' : 'Grand Total'}</FormLabel>
                    <Switch size={'lg'} variant={"totalStyle"} isChecked={totalIsItemized} onChange={(e) => setTotalIsItemized(e.target.checked)} />
                </HStack>
            </FormControl>


            <HStack spacing={"2rem"}>
                <MultipleContainers initialItems={productIds} initialTables={tables} />
            </HStack >

            <Text fontSize={'medium'}>Do you want the description value displayed?</Text>
            <FormControl >
                <HStack justifyContent={'center'}>
                    <FormLabel>{showDescription ? 'Show description' : 'Hide Description'}</FormLabel>
                    <Switch size={'lg'} variant={"totalStyle"} isChecked={showDescription} onChange={(e) => setShowDescription(e.target.checked)} />
                </HStack>
            </FormControl>
        </VStack>
    )
}