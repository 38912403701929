import { Flex, useColorModeValue, VStack, useDisclosure } from "@chakra-ui/react"
import TanTable from "../components/TanStackTable/TanTable"
import contactColumns from "../components/TanStackTable/Columns/Contacts/contactColumns"
import AddCell from "../components/TanStackTable/AddCell"
import AddContactPopup from "../components/AddContactPopup"
import { getContacts } from "../redux/Contact/contactCalls"
import { useSelector, useDispatch } from "react-redux"
import { useEffect } from "react"
import DeleteConfirmation from "../components/DeleteConfirmation"

export default function Contacts() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch()
    const { contacts } = useSelector((state) => state.contacts)
    const { account_id } = useSelector((state) => state.account)

    // HR - we make call to fetch contact information which populates the table
    useEffect(() => {
        getContacts(account_id, dispatch)
    }, [dispatch])

    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
            pt={"2rem"}
        >
            <VStack w={'100%'}>
                {/* HR - AddCell is the "Add New " button */}
                <AddCell type="Contact" openModal={onOpen} />
                {/* HR - contacts table is read, edit, and delete */}
                {contacts && <TanTable columns={contactColumns} defaultData={contacts} type="contacts" />}
            </VStack>
            {/* HR - this form modal opens when the user clicks "Add New" */}
            <AddContactPopup isOpen={isOpen} onClose={onClose} />
            <DeleteConfirmation type={"Contact"} />
        </Flex>
    )
}