import TableCell from "../../TableCell"
import IndeterminateCheckbox from "../../IndeterminateCheckbox"

// HR - contact select columns includes a checkbox at the head of the row, allowing the user to select rows

const contactSelectColumns = [
    {
        header: "Select",
        id: "select",
        cell: ({ row, table }) => {
            return (
                <IndeterminateCheckbox checked={row.getIsSelected()} isDisabled={!row.getCanSelect()} indeterminate={row.getIsSomeSelected()} onChange={row.getToggleSelectedHandler() } />
            )
        }
    },
    {
        header: "Contact ID",
        id: "contact_id",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "contact_id",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Company Name",
        id: "company_name",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "company_name",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Email",
        id: "email",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "email",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Contact Name",
        id: "name",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "name",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Phone Number",
        id: "phone",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "phone",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Street Address",
        id: "address_1",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "address_1",
        type: "text",
        filterFn: "fuzzy"
    },
    // {
    //     header: "Apt Number",
    //     id: "address_2",
    //     cell: ({ row, column, table, getValue }) => {
    //         return (
    //             <TableCell getValue={getValue} row={row} column={column} table={table} />
    //         )
    //     },
    //     accessorKey: "address_2",
    //     filterFn: "fuzzy"
    // },
    {
        header: "City",
        id: "city",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "city",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "State",
        id: "state",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "state",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Country",
        id: "country",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "country",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Zipcode",
        id: "zip",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "zip",
        type: "text",
        filterFn: "fuzzy"
    },
]

export default contactSelectColumns