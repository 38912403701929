import { Flex, useColorModeValue, Box, Stack, Text, Heading, Container } from "@chakra-ui/react"
import Footer from "../../components/Footer"

export default function AboutUs() {
    return (
        <>
            <Flex
                minH={"fit-content"}
                justify={"center"}
                bg={useColorModeValue("gray.50", "gray.800")}
                pt={"2rem"}
            >
                <Stack
                    as={Box}
                    textAlign={'center'}
                    spacing={{ base: 8, md: 14 }}
                    py={[10, 26, 30, 32]}
                    w={'70%'}>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '4xl', sm: '5xl', md: '6xl' }}
                        lineHeight={'110%'}>
                        About
                        <Text as={'span'} color={'blue.400'} ml={'10px'} whiteSpace={'nowrap'}>
                            RapidToolz
                        </Text>
                    </Heading>
                    <Text color={'gray.500'} fontSize={'large'}>
                        In today's fast-paced world, we're all expected to accomplish more with fewer resources. This
                        principle is at the core of our company's tools. How can we complete these essential yet tedious
                        business tasks quickly and efficiently using simple, intuitive tools?
                    </Text>
                    <Text color={'gray.500'} fontSize={'large'}>
                        Not everyone can afford complex software or consulting sessions to "understand" their business
                        needs for creating a tool. Why can't there be a tool that we can test to see if it fits our
                        organization without any hassle?
                    </Text>
                    <Text color={'gray.500'} fontSize={'large'}>
                        At RapidToolz, we create business tools for small businesses that are still using outdated
                        software for quoting and purchase order generation. It's surprising how many small businesses
                        still struggle to streamline this process. We understand this challenge because we were one of
                        them.
                    </Text>
                    <Text color={'gray.500'} fontSize={'large'}>
                        Feel free to trial our software tool to see if it matches your company's needs!
                    </Text>
                </Stack>
            </Flex>
            <Footer />
        </>
    )
}