import TableCell from "../../TableCell"
import EditCell from "../../EditCell"

// HR - contact columns displays contact data and has an edit cell at the tail of the row, allowing the user to edit and delete rows

const contactColumns = [
    {
        header: "Contact ID",
        id: "contact_id",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={'contact'} />
            )
        },
        accessorKey: "contact_id",
        type: "text",
        filterFn: "fuzzy",
    },
    {
        header: "Company Name",
        id: "company_name",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={'contact'} />
            )
        },
        accessorKey: "company_name",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Email",
        id: "email",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={'contact'} />
            )
        },
        accessorKey: "email",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Contact Name",
        id: "name",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={'contact'} />
            )
        },
        accessorKey: "name",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Phone Number",
        id: "phone",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={'contact'} />
            )
        },
        accessorKey: "phone",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Street Address",
        id: "address_1",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={'contact'} />
            )
        },
        accessorKey: "address_1",
        type: "text",
        filterFn: "fuzzy"
    },
    // {
    //     header: "Apt Number",
    //     id: "address_2",
    //     cell: ({ row, column, table, getValue }) => {
    //         return (
    //             <TableCell getValue={getValue} row={row} column={column} table={table} />
    //         )
    //     },
    //     accessorKey: "address_2",
    //     filterFn: "fuzzy"
    // },
    {
        header: "City",
        id: "city",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={'contact'} />
            )
        },
        accessorKey: "city",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "State",
        id: "state",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={'contact'} />
            )
        },
        accessorKey: "state",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Country",
        id: "country",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={'contact'} />
            )
        },
        accessorKey: "country",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Zipcode",
        id: "zip",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={'contact'} />
            )
        },
        accessorKey: "zip",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Actions",
        id: "actions",
        cell: ({ row, table }) => {
            return (
                <EditCell row={row} table={table} tableType={'contact'} />
            )
        },
    }
]

export default contactColumns