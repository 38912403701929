import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    HStack,
    Textarea,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux';
import handleInputChange from '../helper/inputChange';
import { useState } from 'react';
import { addPaymentTerm } from '../redux/PaymentTerm/paymentTermCalls';

export default function AddPaymentTermsPopup({ isOpen, onClose }) {
    const dispatch = useDispatch();
    const { account_id } = useSelector((state) => state.account)
    const [newPaymentTerms, setNewPaymentTerms] = useState({
        terms: "",
        delivery: "",
    })

    function handleAddPaymentTerm(e) {
        e.preventDefault()
        const payload = { data: newPaymentTerms, account_id: account_id }
        addPaymentTerm(payload, dispatch)
        onClose()
        setNewPaymentTerms({
            terms: "",
            delivery: "",
        })
    }

    function inputChange(e) {
        handleInputChange(e.target, setNewPaymentTerms)
    }


    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Payment Term</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form onSubmit={handleAddPaymentTerm}>
                            <VStack spacing={"1rem"}>

                                <FormControl isRequired>
                                    <FormLabel>Payment Terms</FormLabel>
                                    <Input placeholder='Payment Terms' name="terms" value={newPaymentTerms.terms} onChange={inputChange} />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Delivery</FormLabel>
                                    <Textarea placeholder='Delivery' name="delivery" value={newPaymentTerms.delivery} onChange={inputChange} />
                                </FormControl>
                                <HStack>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <Button colorScheme='blue' my={3} mx={5} type="submit">
                                        Save
                                    </Button>
                                </HStack>
                            </VStack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}