import TableCell from "../../TableCell"

const productDiscountQuoteColumns = [
    {
        header: "Product ID",
        id: "product_id",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "product_id",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Name",
        id: "name",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "name",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Description",
        id: "description",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "description",
        type: "text",
        filterFn: "fuzzy"
    },
    {
        header: "Qty.",
        id: "quantity",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "quantity",
        type: 'number',
        filterFn: "fuzzy"
    },
    {
        header: "Discount/Markup",
        id: "discount_options.percentage",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={"createPDF"} />
            )
        },
        accessorKey: "discount_options.percentage",
        type: 'number',
        step: '0.01',
        filterFn: "fuzzy"
    },
    {
        header: "Price",
        id: "calculated_price",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} />
            )
        },
        accessorKey: "calculated_price",
        type: 'number',
        step: '0.01',
        filterFn: "fuzzy"
    },
    {
        header: "Total",
        id: "total",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} disableCell={true} />
            )
        },
        accessorKey: "total",
        type: 'number',
        step: '0.01',
        filterFn: "fuzzy"
    },
]

export default productDiscountQuoteColumns