import { Stepper, Step, StepIndicator, StepStatus, StepNumber, StepIcon, Box, StepSeparator, StepTitle, StepDescription } from '@chakra-ui/react'

export default function MyStepper({ activeStep, steps }) {

    return (
        <Stepper index={activeStep} orientation={'horizontal'}>
            {steps.map((step, index) => {
            return  (
                // HR - only render all steps if screen is medium or above, otherwise only render activeStep
                <Step key={index} hideBelow={index !== activeStep && "md"}>
                    <StepIndicator>
                        <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                        />
                    </StepIndicator>
                    <Box flexShrink='0'>
                        <StepTitle>{step.title}</StepTitle>
                        {step.description && <StepDescription>{step.description}</StepDescription>}
                    </Box>
                    <StepSeparator style={{ width: "3vw" }} />
                </Step>
            )})}
        </Stepper>
    )
}