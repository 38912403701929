import TableCell from "../../TableCell"
import EditCell from "../../EditCell"

// HR - contact columns displays contact data and has an edit cell at the tail of the row, allowing the user to edit and delete rows

const paymentTermsColumns = [
    {
        header: "Payment Terms",
        id: "terms",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={"user"} />
            )
        },
        accessorKey: "terms",
        type: 'text',
        filterFn: "fuzzy"
    },
    {
        header: "Delivery",
        id: "delivery",
        cell: ({ row, column, table, getValue }) => {
            return (
                <TableCell getValue={getValue} row={row} column={column} table={table} tableType={"user"} />
            )
        },
        accessorKey: "delivery",
        type: 'text',
        filterFn: "fuzzy"
    },
    {
        header: "Actions",
        id: "actions",
        cell: ({ row, table }) => {
            return (
                <EditCell row={row} table={table} tableType={"payment term"} />
            )
        },
    }
]

export default paymentTermsColumns