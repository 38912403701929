import { Button, AlertDialog, AlertDialogOverlay, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader } from "@chakra-ui/react"
import { useRef } from "react"

export default function CloseDrawerConfirmation({ isOpen, onClose, onDrawerClose }) {
    const cancelRef = useRef()
    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Cancel Edits
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Did you want to abandon your changes?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Go Back
                            </Button>
                            <Button colorScheme='red' onClick={() => {onClose(); onDrawerClose();}} ml={3}>
                                Abandon Changes
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}