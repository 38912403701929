import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react"
import TanTable from "../components/TanStackTable/TanTable"
import contactSelectColumns from "./TanStackTable/Columns/Contacts/contactSelectColumns"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getContacts } from "../redux/Contact/contactCalls"

export default function ContactModal({ isOpen, onOpen, onClose, setContactRows, handleClick, setContactIndex, type }) {
    const { contacts } = useSelector((state) => state.contacts)
    const { account_id } = useSelector((state) => state.account)
    const { contact_index } = useSelector((state) => state.createDoc)
    const dispatch = useDispatch()
    const [customers, setCustomers] = useState([]);
    const [vendors, setVendors] = useState([]);

    // HR - function for converting contact rowModels to an array of row data
    // we map through the selected rows and return "original" (data) of each
    function handleChangeContact(rowModel) {
        setContactRows(rowModel.map((row) => {
            var index = row.index
            setContactIndex({ [index]: true })
            return row.original
        }))
    }

    useEffect(() => {
        getContacts(account_id, dispatch)
    }, [dispatch])


    useEffect(() => {
        // HR - split customers and vendors into separate arrays
        setCustomers(contacts.filter((contact) => {
            return contact.type === "customer"
        }))
        setVendors(contacts.filter((contact) => {
            return contact.type === "vendor"
        }))
    }, [contacts])

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={"center"} paddingBottom={0}>{type === "Quote" ? "Customer Table" : "Vendor Table"}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody overflowX={'auto'}>

                        <TanTable columns={contactSelectColumns} defaultData={type=== "Quote" ? customers : vendors} type="contactModal" setModalRows={handleChangeContact} selectedIndicies={contact_index} />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleClick}>
                            Done
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}