import { createSlice } from "@reduxjs/toolkit";

export const paymentTermSlice = createSlice({
    name: "paymentTerms",
    initialState: {
        payment_terms: [],
        addPaymentTermProgress: false,
        getPaymentTermsProgress: false,
        updatePaymentTermProgress: false,
        updateFOBprogress: false,
        deletePaymentTermProgress: false,
        terms_content_change: false,
        error: false,
    },
    reducers: {

        // add payment term
        addPaymentTermStart: (state) => {
            state.addPaymentTermProgress = true
        },
        addPaymentTermSuccess: (state, action) => {
            state.payment_terms.push(action.payload)
            state.addPaymentTermProgress = false
        },
        addPaymentTermFailure: (state) => {
            state.error = true
            state.addPaymentTermProgress = false
        },

        // get payment terms
        getPaymentTermsStart: (state) => {
            state.getPaymentTermsProgress = true
        },
        getPaymentTermsSuccess: (state, action) => {
            state.payment_terms = action.payload.payment_terms
            state.getPaymentTermsProgress = false
        },
        getPaymentTermsFailure: (state) => {
            state.error = true
            state.getPaymentTermsProgress = false
        },

        // update payment term
        updatePaymentTermStart: (state) => {
            state.updatePaymentTermProgress = true
        },
        updatePaymentTermSuccess: (state, action) => {
            state.updatePaymentTermProgress = false
        },
        updatePaymentTermFailure: (state) => {
            state.error = true
            state.updatePaymentTermProgress = false
        },

        updateFOBStart: (state) => {
            state.updateFOBprogress = true
        },
        updateFOBSuccess: (state, action) => {
            state.default_fob = action.payload
            state.updateFOBprogress = false
        },
        updateFOBailure: (state) => {
            state.error = true
            state.updateFOBprogress = false
        },

        // delete payment term
        deletePaymentTermStart: (state) => {
            state.deletePaymentTermProgress = true
        },
        deletePaymentTermSuccess: (state, action) => {
            state.payment_terms = state.payment_terms.filter(
                (payment_term) => payment_term._id !== action.payload
            )
            state.deletePaymentTermProgress = false
        },
        deletePaymentTermFailure: (state) => {
            state.error = true
            state.deletePaymentTermProgress = false
        },

        termsContentChange: (state) => {
            state.terms_content_change = true
        },
        termsContentSave: (state) => {
            state.terms_content_change = false
        },

    },
})

export const {
    getPaymentTermsStart,
    getPaymentTermsSuccess,
    getPaymentTermsFailure,
    addPaymentTermStart,
    addPaymentTermSuccess,
    addPaymentTermFailure,
    updatePaymentTermStart,
    updatePaymentTermSuccess,
    updatePaymentTermFailure,
    updateFOBStart,
    updateFOBSuccess,
    updateFOBailure,
    deletePaymentTermStart,
    deletePaymentTermSuccess,
    deletePaymentTermFailure,
    termsContentChange,
    termsContentSave,
} = paymentTermSlice.actions

export default paymentTermSlice.reducer