import { PDFViewer, pdf } from '@react-pdf/renderer'
import MyDocument from '../PreviewPDF/MyDocument';
import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, HStack, Text, VStack, FormControl, FormLabel, Input, Menu, MenuButton, MenuList, MenuItem, Divider, Box, Stack } from '@chakra-ui/react';
import { createDocument, getLastDocumentId, updateDocument } from '../../redux/Document/documentCalls';
import PDFMerger from 'pdf-merger-js';
import { saveAs } from "file-saver"
import { toast } from 'react-toastify';
import { ChevronDownIcon } from '@chakra-ui/icons'
import companyLogo2 from "../../images/default_logo.png"

const MemoDoc = memo(MyDocument);

export default function FinalizePDF({ type, incrementStep, mode }) {
    const [uploadedPDF, setUploadedPDF] = useState([])
    const [currentLogo, setCurrentLogo] = useState(companyLogo2)
    const dispatch = useDispatch()

    const { doc_number } = useSelector((state) => state.createDoc)
    const { version } = useSelector((state) => state.createDoc)
    const { date } = useSelector((state) => state.createDoc)
    const { contact } = useSelector((state) => state.createDoc)
    const { contact_index } = useSelector((state) => state.createDoc)
    const { products } = useSelector((state) => state.createDoc)
    const { tables } = useSelector((state) => state.createDoc)
    const { product_indicies } = useSelector((state) => state.createDoc)
    const { content } = useSelector((state) => state.createDoc)
    const { total } = useSelector((state) => state.createDoc)
    const { created_by } = useSelector((state) => state.createDoc)
    const { shipping_content } = useSelector((state) => state.createDoc)
    const { related_quote } = useSelector((state) => state.createDoc)
    const { edit_mode } = useSelector((state) => state.createDoc)
    const { discounts_enabled } = useSelector((state) => state.createDoc)
    const { global_discount_options } = useSelector((state) => state.createDoc)
    const { terms_content } = useSelector((state) => state.createDoc)
    const { show_description } = useSelector((state) => state.createDoc)
    const { user_name } = useSelector((state) => state.auth)
    const { account_name } = useSelector((state) => state.account)
    const { address_1 } = useSelector((state) => state.account)
    const { city } = useSelector((state) => state.account)
    const { state } = useSelector((state) => state.account)
    const { zip } = useSelector((state) => state.account)
    const { currency } = useSelector((state) => state.account)
    const { account_phone } = useSelector((state) => state.account)
    const { account_fax } = useSelector((state) => state.account)
    const { account_email } = useSelector((state) => state.account)
    const { account_id } = useSelector((state) => state.account)
    const { resources } = useSelector((state) => state.resources)
    const { account_logo } = useSelector((state) => state.account)
    const { account_terms } = useSelector((state) => state.account)
    const { default_valid_for } = useSelector((state) => state.account)
    const { default_terms_text } = useSelector((state) => state.account)

    // HR - if an account logo is assigned, get the resource_url from the resources redux state to render the correct image on screen
    useEffect(() => {
        if (account_logo) {
            var logoResource = resources.find((resource) => {
                return resource._id === account_logo
            })
            setCurrentLogo(logoResource.resource_url)
        }
    }, [account_logo, resources])

    // HR - create new document from scratch or from existing document
    function handleCreateDocument() {
        const createPayload = {
            type: type, active: 'active', is_complete: true, date_created: date, doc_number: doc_number, account_id: account_id, version: 1, contact: contact,
            contact_index: contact_index, products: products, tables: tables, product_indicies: product_indicies, content: content, total: total, created_by: user_name,
            shipping_content: shipping_content, terms_content: terms_content, discounts_enabled: discounts_enabled, global_discount_options: global_discount_options, show_description: show_description
        }
        if (type === "Quote") {
            createPayload.quote_status = "Active"
        }
        createDocument(createPayload, dispatch)
        getLastDocumentId(account_id, dispatch)
        incrementStep()
    }

    // HR - create invoice from previous quote
    function handleCreateInvoice() {
        var createInvoicePayload = {
            type: "Invoice", active: 'active', is_complete: true, date_created: date, doc_number: doc_number, account_id: account_id, version: 1,
            contact: contact, contact_index: contact_index, products: products, tables: tables, product_indicies: product_indicies, content: content, total: total,
            created_by: user_name, shipping_content: shipping_content, terms_content: terms_content, discounts_enabled: discounts_enabled,
            global_discount_options: global_discount_options, show_description: show_description
        }
        // HR - if editing an invoice as a new invoice, do not give a related_quote value
        if (edit_mode !== "new") {
            createInvoicePayload.related_quote = related_quote
        }
        createDocument(createInvoicePayload, dispatch)
        getLastDocumentId(account_id, dispatch)
        const editPayload = { quote_status: 'Inactive (Won)', doc_number: related_quote }
        updateDocument(editPayload, dispatch)
        incrementStep()
    }

    // HR - save changes to existing document
    function handleEditDocument() {
        const editPayload = {
            type: type, active: 'active', is_complete: true, date_updated: date, doc_number: doc_number, account_id: account_id, version: version + 1,
            contact: contact, contact_index: contact_index, products: products, tables: tables, product_indicies: product_indicies, content: content,
            total: total, created_by: created_by, updated_by: user_name, shipping_content: shipping_content, terms_content: terms_content, discounts_enabled: discounts_enabled,
            global_discount_options: global_discount_options, show_description: show_description
        }
        updateDocument(editPayload, dispatch)
        incrementStep()
    }

    function handlePDFUpload(e) {
        var fileArray = []
        for (let index = 0; index < e.target.files.length; index++) {
            let fileBlob = new Blob([e.target.files[index]], { type: 'application/pdf' })
            fileArray[index] = fileBlob

        }
        setUploadedPDF(fileArray)
    }

    async function mergePDF() {
        const doc = <MemoDoc type={type} doc_number={doc_number} user_name={user_name} account_id={account_id} account_name={account_name} date={date} aPhone={account_phone} aEmail={account_email}
            aFax={account_fax} aAddress_1={address_1} aCity={city} aState={state} aZip={zip} cName={contact.company_name} cAddress_1={contact.address_1} cCity={contact.city}
            cState={contact.state} cCountry={contact.country} cZip={contact.zip} cPhone={contact.phone} cEmail={contact.email} products={products} tables={tables} content={content}
            total={total} terms_content={terms_content} shipping_content={shipping_content} discounts_enabled={discounts_enabled} logo={currentLogo} default_valid_for={default_valid_for}
            default_terms_text={default_terms_text} currency={currency} show_description={show_description}/>
        const asPDF = pdf()
        asPDF.updateContainer(doc)

        let appendixBlob = await asPDF.toBlob()

        if (type === "PO" || (account_terms === null || !account_terms || account_terms.length === 0)) {
            return await appendixBlob
        } else {
            const termsPDF = resources.filter((resource) => resource._id === account_terms)[0]
            const merger = new PDFMerger()
            await Promise.all([appendixBlob, termsPDF.resource_url].map(async (file) => await merger.add(file, null)))
            return await merger.saveAsBlob()
        }
    }

    async function fetchMergePDF(attachments) {
        if (uploadedPDF.length > 0 && attachments) { // downlaod with attachments (need to merge pdfs)
            const initialQuote = await mergePDF() // if quote, this will include terms, po will not
            const initialArray = [initialQuote]
            const concatArray = initialArray.concat(uploadedPDF)
            const merger2 = new PDFMerger()
            await Promise.all(concatArray.map(async (file) => await merger2.add(file, null)))
            const mergedPdf2 = await merger2.saveAsBlob()
            saveAs(mergedPdf2, `${type}_${doc_number}`)
        } else if (uploadedPDF.length === 0 && !attachments) {
            const initialQuote = await mergePDF()
            saveAs(initialQuote, `${type}_${doc_number}`)
        } else {
            toast.error("No attachments uploaded, please try again!")
        }
    }

    return (
        <Box>
            <Stack direction={['column','column', 'column','row']} >
                <Container centerContent mb={"1rem"} w={['90vw', '80vw']}>
                    <VStack spacing={2}>
                        {/* HR - user can click to download excel template to fill out contact data */}
                        {mode !== 'view' ? <>
                            <Text>You can download the document with/without attachments,</Text>
                            <Text mb={2} as={'em'}>but don't forget to save the document!</Text>
                            <Text>Note: The attachments are NOT saved to the database</Text>
                            {(type === "Quote" || type === "Invoice") &&
                                <>
                                    <Divider />
                                    <Text>If you have uploaded a terms and conditions file through the</Text>
                                    <Text>settings page, that PDF will be automatically </Text>
                                    <Text>attached for quotes and invoices!</Text>
                                </>
                            }
                        </> : <>
                            <Text>You can download the document with/without attachments</Text>
                            <Text>Note: The attachments are NOT saved to the database</Text>
                            {(type === "Quote" || type === "Invoice") &&
                                <>
                                    <Divider />
                                    <Text>If you have uploaded a terms and conditions file through the</Text>
                                    <Text>settings page, that PDF will be automatically </Text>
                                    <Text>attached for quotes and invoices!</Text>
                                </>
                            }
                        </>}
                        <FormControl mb={"1rem"} mt={"1rem"}>
                            <FormLabel>Upload Attachments</FormLabel>
                            <Input placeholder='Choose File' type="file" accept='.pdf' variant={"flushed"} multiple onChange={handlePDFUpload} />
                        </FormControl>
                        <Menu>
                            <MenuButton as={Button} colorScheme={'blue'} rightIcon={<ChevronDownIcon />}>Download</MenuButton>
                            <MenuList>
                                <MenuItem onClick={() => fetchMergePDF(false)}>No Attachments</MenuItem>
                                <MenuItem onClick={() => fetchMergePDF(true)}>With Attachments</MenuItem>
                            </MenuList>
                        </Menu>
                        <Container centerContent mb={"1rem"} mt={"1rem"}>
                            {mode !== 'view' && <>
                                <Text as={"em"}>Once you save, you will not be able to return to this page.</Text>
                                <Text>if you need to return to make changes or download,</Text>
                                <Text mb={2}>you can open up this document through {type} History</Text>
                            </>}

                            {mode === "create" && <Button colorScheme='blue' onClick={handleCreateDocument}>Save Document</Button>}
                            {mode === 'new' && <Button colorScheme='orange' onClick={handleCreateDocument}>Save as New Document</Button>}
                            {mode === 'existing' && <Button colorScheme='green' onClick={handleEditDocument}>Save Changes</Button>}
                            {mode === 'invoice' && <Button colorScheme='purple' onClick={handleCreateInvoice}>Save As Invoice</Button>}
                        </Container>
                    </VStack>
                </Container>
                <VStack>
                    <PDFViewer  w={['90vw', '80vw', '70vw', '40vw', '50vw']} style={{height: "65vh" }} showToolbar={false}>
                        <MemoDoc type={type} doc_number={doc_number} user_name={user_name} account_id={account_id} account_name={account_name} date={date} aPhone={account_phone} aEmail={account_email}
                            aFax={account_fax} aAddress_1={address_1} aCity={city} aState={state} aZip={zip} cName={contact.company_name} cAddress_1={contact.address_1} cCity={contact.city}
                            cState={contact.state} cCountry={contact.country} cZip={contact.zip} cPhone={contact.phone} cEmail={contact.email} products={products} tables={tables} content={content}
                            total={total} terms_content={terms_content} shipping_content={shipping_content} discounts_enabled={discounts_enabled} logo={currentLogo} default_valid_for={default_valid_for}
                            default_terms_text={default_terms_text} currency={currency} show_description={show_description}/>
                    </PDFViewer>
                </VStack>
            </Stack>
        </Box>
    )
}