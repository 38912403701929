import { useDisclosure, HStack, Tooltip, IconButton } from "@chakra-ui/react"
import { FiCheck, FiX, FiEdit, FiTrash2 } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux';
import { deleteContact, updateContact } from "../../redux/Contact/contactCalls";
import { deleteProduct, updateProduct } from "../../redux/Product/productCalls"
import { deleteUser, updateUser } from "../../redux/User/userCalls";
import DeleteConfirmation from "../DeleteConfirmation";
import { deleteItemize, updateItemize } from "../../redux/CreateDoc/createDocSlice";
import { deletePaymentTerm, updatePaymentTerm } from "../../redux/PaymentTerm/paymentTermCalls";

export default function EditCell({ row, table, tableType }) {
    const meta = table.options.meta
    const dispatch = useDispatch();
    const { account_id } = useSelector((state) => state.account)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const setEditedRows = async (e) => {
        const elName = e.currentTarget.name

        meta?.setEditedRows(old => {
            return ({
                ...old,
                [row.id]: !old[row.id]
            })
        })

        if (elName === "done") {
            if (tableType === "contact") {
                await updateContact({ data: row.original, id: row.original._id }, dispatch)
            } else if (tableType === "product") {
                await updateProduct({ data: row.original, id: row.original._id }, dispatch)
            } else if (tableType === "user") {
                await updateUser({ data: row.original.role, id: row.original._id, update: "role" }, dispatch)
            } else if (tableType === "createPDF") {
                dispatch(updateItemize(row.original))
            } else if (tableType === "payment term") {
                await updatePaymentTerm({ account_id: account_id, data: row.original }, dispatch)
            }
            return
        }

        if (elName !== "edit") {
            meta?.revertData(row.index, e.currentTarget.name === "cancel")
        }
    }

    function removeRow() {
        if (tableType === "contact") {
            deleteContact(row.original._id, dispatch)
        } else if (tableType === "product") {
            deleteProduct(row.original._id, dispatch)
        } else if (tableType === "user") {
            deleteUser(row.original._id, dispatch)
        } else if (tableType === "createPDF") {
            dispatch(deleteItemize({ id: row.original._id, index: row.index }))
        } else if (tableType === "payment term") {
            deletePaymentTerm({ account_id: account_id, term_id: row.original._id }, dispatch)
        }
        meta?.removeRow(row.index);
        onClose()
    };

    return meta?.editedRows[row.id] ? (
        <>
            <HStack spacing={'1rem'}>
                <Tooltip label='Save' fontSize='sm'>
                    <IconButton onClick={setEditedRows} variant={"outline"} borderColor={"rgb(56, 161, 105)"} icon={<FiCheck color="rgb(56, 161, 105)" />} name="done" />
                </Tooltip>
                <Tooltip label='Cancel' fontSize='sm'>
                    <IconButton onClick={setEditedRows} variant={"outline"} borderColor={"rgb(229, 62, 62)"} icon={<FiX color="rgb(229, 62, 62)" />} name="cancel" />
                </Tooltip>
            </HStack>
        </>
    ) : (
        <>
            <HStack spacing={'1rem'}>
                <Tooltip label='Edit' fontSize='sm'>
                    <IconButton onClick={setEditedRows} variant={"outline"} borderColor={"rgb(56, 161, 105)"} icon={<FiEdit color="rgb(56, 161, 105)" />} name="edit"  hidden={(tableType === "user" && row.original.role === "owner")}/>
                </Tooltip>
                <Tooltip label='Delete' fontSize='sm'>
                    <IconButton onClick={onOpen} variant={"outline"} borderColor={"rgb(229, 62, 62)"} icon={<FiTrash2 color="rgb(229, 62, 62)" />} name="remove" hidden={(tableType === "user" && row.original.role === "owner")} />
                </Tooltip>
                <DeleteConfirmation type={tableType} isOpen={isOpen} onOpen={onOpen} onClose={onClose} removeRow={removeRow} />
            </HStack>
        </>
    )
}
