import { toast } from 'react-toastify'
import * as actions from "./paymentTermSlice"
import axios from 'axios'
import getApiUri from '../../helper/getApiUri'

const apiUrl = getApiUri() + "/paymentTerms"

export const addPaymentTerm = async (payload, dispatch) => {
    dispatch(actions.addPaymentTermStart())
    try {
        const { data } = await axios.post(apiUrl, payload)
        dispatch(actions.addPaymentTermSuccess(data.data))
        return true
    } catch (error) {
        dispatch(actions.addPaymentTermFailure())
        toast.error(error.response.data.message);
        return false
    }
}

export const getPaymentTerms = async (payload, dispatch) => {
    dispatch(actions.getPaymentTermsStart())
    try {
        const { data } = await axios.get(apiUrl + `/${payload}`)
        dispatch(actions.getPaymentTermsSuccess(data))
        return true
    } catch (error) {
        dispatch(actions.getPaymentTermsFailure())
        toast.error(error.response.data.message);
        return false
    }
}

export const updatePaymentTerm = async (payload, dispatch) => {
    dispatch(actions.updatePaymentTermStart())
    try {
        const url = apiUrl + '/' + payload.data._id
        const { data } = await axios.put(url, payload)
        dispatch(actions.updatePaymentTermSuccess(data.data))
        toast.success(data.message)
        return true
    } catch (error) {
        console.log(error)
        toast.error(error.response.data.message);
        getPaymentTerms(payload.account_id, dispatch)
        dispatch(actions.updatePaymentTermFailure())
        return false
    }
}

export const deletePaymentTerm = async (payload, dispatch) => {
    dispatch(actions.deletePaymentTermStart())
    try {
        const { data } = await axios.delete(apiUrl + "/" + payload.term_id, { data: { account_id: payload.account_id } })
        dispatch(actions.deletePaymentTermSuccess(payload.term_id))
        toast.success(data.message)
        return true
    } catch (error) {
        toast.error(error.response.data.message);
        dispatch(actions.deletePaymentTermFailure())
        return false
    }
}