import { FormLabel, Input, HStack, Textarea, FormControl, Button, VStack, Select } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import handleInputChange from '../../helper/inputChange';
import { organizeSuccess, shippingContentChange, shippingContentSave } from '../../redux/CreateDoc/createDocSlice';
import { toast } from 'react-toastify';

export default function OrganizePDF({ type, mode }) {
    const dispatch = useDispatch()
    const [shippingContent, setShippingContent] = useState({
        shipping_via: "ground",
        required_by: "",
        project_name: "",
        category: "",
        sub_category: "",
        notes: "",
    })
    const { shipping_content } = useSelector((state) => state.createDoc)
    const { shipping_content_change } = useSelector((state) => state.createDoc)

    useEffect(() => {
        if (Object.keys(shipping_content).length > 0) {
            setShippingContent({
                shipping_via: shipping_content.shipping_via,
                required_by: shipping_content.required_by,
                project_name: shipping_content.project_name,
                category: shipping_content.category,
                sub_category: shipping_content.sub_category,
                notes: shipping_content.notes
            })
        }
    }, [])

    useEffect(() => {
        dispatch(organizeSuccess(shippingContent))
    }, [shippingContent])

    function inputChange(e) {
        handleInputChange(e.target, setShippingContent)
        dispatch(shippingContentChange())
    }

    function handleSubmitForm(e) {
        e.preventDefault()
        dispatch(shippingContentSave(shippingContent))
        toast.success("Successfully saved!")
    }

    return (
        <>
            <form onSubmit={handleSubmitForm}>
                <VStack spacing={5} mt={5} minW={"15vw"}>
                    <FormControl isRequired>
                        <FormLabel>Shipping Via</FormLabel>
                        {/* <Input
                            onChange={inputChange}
                            disabled={mode === 'view' ? true : false}
                            style={mode === 'view' ? { opacity: '1', cursor: 'default' } : null}
                        /> */}
                        <Select onChange={inputChange} name='shipping_via' value={shippingContent.shipping_via} variant={"filled"} disabled={mode === 'view' ? true : false}
                            style={mode === 'view' ? { opacity: '1', cursor: 'default' } : null} >
                            <option value='ground'>Ground</option>
                            <option value='air_freight'>Air Freight</option>
                            <option value='best_way'>Best Way</option>
                            <option value='2_day'>2 Day</option>
                            <option value='overnight'>Overnight</option>
                            <option value='collect'>Collect</option>
                            <option value='pp_add'>Pre-pay & Add</option>
                        </Select>
                    </FormControl>


                    <FormControl isRequired>
                        <FormLabel>Required By</FormLabel>
                        <Input
                            value={shippingContent.required_by}
                            name="required_by"
                            onChange={inputChange}
                            type="date"
                            variant={"filled"}
                            disabled={mode === 'view' ? true : false}
                            style={mode === 'view' ? { opacity: '1', cursor: 'default' } : null}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Project Name</FormLabel>
                        <Input
                            value={shippingContent.project_name}
                            name="project_name"
                            onChange={inputChange}
                            type="text"
                            variant={"filled"}
                            disabled={mode === 'view' ? true : false}
                            style={mode === 'view' ? { opacity: '1', cursor: 'default' } : null}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Category</FormLabel>
                        <Input
                            value={shippingContent.category}
                            name="category"
                            onChange={inputChange}
                            type="text"
                            variant={"filled"}
                            disabled={mode === 'view' ? true : false}
                            style={mode === 'view' ? { opacity: '1', cursor: 'default' } : null}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Sub-Category</FormLabel>
                        <Input
                            value={shippingContent.sub_category}
                            name="sub_category"
                            onChange={inputChange}
                            type="text"
                            variant={"filled"}
                            disabled={mode === 'view' ? true : false}
                            style={mode === 'view' ? { opacity: '1', cursor: 'default' } : null}
                        />
                    </FormControl>
                    <FormControl >
                        <FormLabel>Notes</FormLabel>
                        <Textarea
                            value={shippingContent.notes}
                            name="notes"
                            onChange={inputChange}
                            type="text"
                            placeholder='Enter extra notes here...'
                            variant={"filled"}
                            disabled={mode === 'view' ? true : false}
                            style={mode === 'view' ? { opacity: '1', cursor: 'default' } : null}
                        />
                    </FormControl>
                    {mode !== 'view' && <Button colorScheme={shipping_content_change ? "green" : "gray"} isDisabled={!shipping_content_change} type='submit'>Save</Button>}
                </VStack>
            </form>
        </>
    )
}