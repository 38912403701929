import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
        border: '1px solid black',
        padding: 5,
    },
    id: {
        width: "15.5%",
        paddingHorizontal: 2,
        marginHorizontal: 2
    },
    name: {
        width: "23.0%",
        paddingHorizontal: 2,
        marginHorizontal: 2,
    },
    desc: {
        width: "28.5%",
        paddingHorizontal: 5,
        marginHorizontal: 2
    },
    list_price: {
        width: "13%",
        paddingHorizontal: 2,
        marginHorizontal: 2,
    },
    cost: {
        width: "13%",
        paddingHorizontal: 2,
        marginHorizontal: 2
    },
    quantity: {
        width: "6.0%",
        paddingHorizontal: 2,
        marginHorizontal: 2
    },
    total: {
        width: "11.5%",
        paddingHorizontal: 2,
        marginHorizontal: 2
    }
});


const styles2 = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
        border: '1px solid black',
        padding: 5,
    },
    id: {
        width: "22%",
        paddingHorizontal: 2,
        marginHorizontal: 2
    },
    name: {
        width: "36.0%",
        paddingHorizontal: 2,
        marginHorizontal: 2,
    },
    list_price: {
        width: "16%",
        paddingHorizontal: 2,
        marginHorizontal: 2,
    },
    cost: {
        width: "16%",
        paddingHorizontal: 2,
        marginHorizontal: 2
    },
    quantity: {
        width: "8.0%",
        paddingHorizontal: 2,
        marginHorizontal: 2
    },
    total: {
        width: "15.5%",
        paddingHorizontal: 2,
        marginHorizontal: 2
    }
});



// HR - splits up long name, description, and id strings into multiples lines for table formatting
function addLineBreak(quality, format) {
    var lineBrk = 20
    if(format === "id"){
        lineBrk = 13
    }
    if (quality.length > lineBrk && quality.indexOf(' ') === -1) {
        var returnQuality = ""
        for (let i = 0; i < quality.length; i++) {
            if (i % lineBrk !== 0) {
                returnQuality += quality[i]
            }else {
                returnQuality += " " + quality[i]
            }
        }
        return returnQuality
    } else {
        return quality
    }
}

// HR this maps item data (or headers) into a row of text
const TableRow = ({ items, style, type, discounts_enabled, currency, show_description }) => {
    console.log(show_description)

    var whichStyle = styles
    if(!show_description){
        whichStyle = styles2
    }

    // HR - created formatter to transform 2500 -> $2,500.00
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
    })

    if (items) {
        const rows = items.map((item, index) => (
            <View style={whichStyle.row} key={index}>
                <Text style={[whichStyle.id, style]}>{addLineBreak(item.product_id, "id")}</Text>
                <Text style={[whichStyle.name, style]}>{addLineBreak(item.name, "name")}</Text>
                {show_description && <Text style={[whichStyle.desc, style]}>{addLineBreak(item.description, "desc")}</Text>}


                {type === "PO" && item.cost === "Cost" && <Text style={[whichStyle.cost, style]}>{item.cost}</Text>}
                {type === "PO" && item.cost !== "Cost" && <Text style={[whichStyle.cost, style]}> {formatter.format(item.cost)}</Text>}
                {discounts_enabled ?
                    <>
                        {item.calculated_price === "Price" && <Text style={[whichStyle.list_price, style]}>{item.calculated_price}</Text>}
                        {item.calculated_price !== "Price" && <Text style={[whichStyle.list_price, style]}> {formatter.format(item.calculated_price)}</Text>}
                    </>
                    :
                    <>
                        {(type === "Quote" || type === "Invoice") && item.list_price === "Price" && <Text style={[whichStyle.list_price, style]}>{item.list_price}</Text>}
                        {(type === "Quote" || type === "Invoice") && item.list_price !== "Price" && <Text style={[whichStyle.list_price, style]}> {formatter.format(item.list_price)}</Text>}
                    </>
                }

                <Text style={[whichStyle.quantity, style]}>{item.quantity}</Text>
                {item.total === "Total" ?
                    <Text style={[whichStyle.total, style]}>{item.total}</Text>
                    :
                    <Text style={[whichStyle.total, style]}> {formatter.format(item.total)}</Text>
                }
            </View>
        ));
        return <Fragment>{rows}</Fragment>;
    } else {
        return null
    }
};

export default TableRow;
