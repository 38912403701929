import { Stack, HStack, Text, Input, VStack, FormControl, FormLabel, Button,  Link, Tooltip,  Textarea } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { uploadTerms, getResources, deleteTerms } from "../../redux/Resource/resourceCalls";
import { FiExternalLink } from 'react-icons/fi'
import { updateAccount } from "../../redux/Account/accountCalls";
import { toast } from "react-toastify";

export default function ManageTermsAndConditions(styles) {
    const [termsFile, setTermsFile] = useState({})
    const { account_id } = useSelector((state) => state.account)
    const { account_terms } = useSelector((state) => state.account)
    const { resources } = useSelector((state) => state.resources)
    const { createResourceProgress } = useSelector((state) => state.resources)
    const { default_fob } = useSelector((state) => state.account)
    const { default_valid_for } = useSelector((state) => state.account)
    const { default_terms_text } = useSelector((state) => state.account)
    const [termsResource, setTermsResource] = useState({})
    const [defaultFob, setDefaultFob] = useState("")
    const [isValidFor, setIsValidFor] = useState(15)
    const [termsText, setTermsText] = useState({
        text_1: "",
        text_2: ""
    })
    const [textIsDisabled, setTextIsDisabled] = useState(true)
    const dispatch = useDispatch()

    // HR - this is used to grab the terms and conditions resource from the redux state
    useEffect(() => {
        if (account_terms && account_terms.length > 0) {
            setTermsResource(resources.filter((resource) => resource._id === account_terms)[0])
        }
    }, [account_terms, resources])

    // HR - this is used to refetch the updated resources once a new resource is created
    useEffect(() => {
        getResources(account_id, dispatch)
    }, [createResourceProgress, account_terms])

    useEffect(() => {
        setDefaultFob(default_fob)
        setIsValidFor(default_valid_for)
        setTermsText(default_terms_text)
    }, [default_fob, default_terms_text])


    async function handleSubmitTerms(e) {
        e.preventDefault()
        if (!termsFile) {
            toast.error("No file was selected!")
        } else {
            // HR - create formData object to easily be handle my backend multer library
            const formData = new FormData()
            formData.append("myFile", termsFile)
            formData.append("myTitle", "Terms and Conditions")
            formData.append("account_id", account_id)
            formData.append("currentTerms", account_terms)
            uploadTerms(formData, dispatch)
            setTermsFile(null)
            document.getElementById("fileInput").value = null
        }
    }

    function handleDeleteTerms() {
        deleteTerms(account_terms, dispatch)
    }

    function handleSaveTermsText() {
        updateAccount({ id: account_id, data: { default_fob: defaultFob, default_terms_text: termsText, default_valid_for: isValidFor } }, dispatch)
        setTextIsDisabled(true)
    }

    return (
        <HStack justifyContent={'center'} mx={'2rem'} width={['40vw','60vw']}>
            <Stack m={'3rem'}>
                <HStack mb={"2rem"} justifyContent={'center'}>
                    <Text fontSize="2xl" fontFamily="heading" fontWeight="light">Upload Terms and Conditions</Text>
                </HStack>
                <Text fontSize="lg" fontFamily="heading" fontWeight="light">If you have saved a PDF to use as your Terms and Conditions, it will automatically be merged into any quotes you create</Text>
                <form onSubmit={handleSubmitTerms}>
                    <VStack spacing={'2rem'}>
                        <FormControl>
                            <FormLabel>Please select a PDF to use</FormLabel>
                            <Input placeholder='Choose File' type="file" accept={'application/pdf'} variant={"flushed"} onChange={(e) => setTermsFile(e.target.files[0])} id="fileInput" />
                        </FormControl>
                        <HStack>
                            {account_terms && <Link href={termsResource.resource_url} isExternal><Button colorScheme={"orange"} leftIcon={<FiExternalLink />} onClick={() => setTextIsDisabled(false)}>View File</Button></Link>}
                            <Tooltip label="This will replace the current file if previously set"><Button colorScheme={"green"} type="submit">Submit File</Button></Tooltip>
                            <Tooltip label="This will remove the current file if previously set"><Button colorScheme={"red"} onClick={handleDeleteTerms}>Delete File</Button></Tooltip>
                        </HStack>
                    </VStack>
                </form>
                <HStack my={"3rem"} justifyContent={'center'}>
                    <Text fontSize="2xl" fontFamily="heading" fontWeight="light">Set Terms Defaults</Text>
                </HStack>
                <VStack spacing={'2rem'}>
                    <FormControl>
                        <FormLabel>Default F.O.B.</FormLabel>
                        <Input
                            style={textIsDisabled ? styles.disabledInput : styles.normalInput}
                            type="string"
                            variant={"filled"}
                            value={defaultFob}
                            onChange={(e) => setDefaultFob(e.target.value)}
                            disabled={textIsDisabled}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Quotation Valid For (Days)</FormLabel>
                        <Input
                            style={textIsDisabled ? styles.disabledInput : styles.normalInput}
                            type="number"
                            variant={"filled"}
                            value={isValidFor}
                            onChange={(e) => setIsValidFor(e.target.value)}
                            disabled={textIsDisabled}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Terms Text 1</FormLabel>
                        <Textarea
                            style={textIsDisabled ? styles.disabledInput : styles.normalInput}
                            type="string"
                            name="text_1"
                            variant={"filled"}
                            value={termsText.text_1}
                            onChange={(e) => setTermsText({ ...termsText, text_1: e.target.value })}
                            disabled={textIsDisabled}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Terms Text 2</FormLabel>
                        <Textarea
                            style={textIsDisabled ? styles.disabledInput : styles.normalInput}
                            type="string"
                            name="text_2"
                            variant={"filled"}
                            value={termsText.text_2}
                            onChange={(e) => setTermsText({ ...termsText, text_2: e.target.value })}
                            disabled={textIsDisabled}
                        />
                    </FormControl>
                    {/* HR - switch button color and text depending on isDisabled state */}
                    {textIsDisabled ?
                        <Button w="10rem" colorScheme={"purple"} onClick={() => setTextIsDisabled(false)}>Edit Info</Button> :
                        <Button w="10rem" colorScheme={"green"} onClick={handleSaveTermsText}>Save Info</Button>
                    }
                </VStack>
            </Stack>
        </HStack>
    )
}