import {
    Box,
    Stack,
    FormControl,
    FormLabel,
    Input,
    Button,
    Text,
    useColorModeValue,
    Divider,
    Container,
    Center
} from '@chakra-ui/react'
import { NavLink } from "react-router-dom"
import { useState } from 'react'
import handleInputChange from "../../helper/inputChange"
import { createAccount, verifyGoogle } from '../../redux/Account/accountCalls'
import { useDispatch } from "react-redux"
import GoogleButton from '../GoogleButton'
import { useGoogleLogin } from '@react-oauth/google'
import { toast } from 'react-toastify'

export default function CompanyForm({ companyData, setCompanyData, userData, setUserData }) {
    const dispatch = useDispatch()

    // HR - if primary fields are not empty, call backend to create new account and increment to next step
    // HR - keep in mind that this account has an INACTIVE subscription, so technically not usable yet
    function handleCreateAccount(e) {
        e.preventDefault()
        if (companyData.name !== "" && userData.email !== "") {
            createAccount({ company: companyData, user: userData }, dispatch)
        }else if(userData.email === ""){
            toast.error("You must sign up owner with Google email account to continue!")
        }
    }

    // HR - this function is required to handle onChange for inputs
    function inputChange(e) {
        handleInputChange(e.target, setCompanyData)
    }

    const googSignup = useGoogleLogin({
        onSuccess: async tokenResponse => {
            const returnData = await verifyGoogle({ token: tokenResponse.access_token, company: companyData, user: userData }, dispatch)
            setUserData({ ...userData, email: returnData })
        }
    })

    return (
        <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
            w={"sm"}
        >
            <Stack spacing={4}>
                <form onSubmit={handleCreateAccount}>
                    <FormControl isRequired my={3}>
                        <FormLabel>Company Name</FormLabel>
                        <Input type="text" name="name" value={companyData.name} onChange={inputChange} />
                    </FormControl>
                    <FormControl isRequired my={3}>
                        <FormLabel>Company Email</FormLabel>
                        <Input type="text" name="email" value={companyData.email} onChange={inputChange} />
                    </FormControl>
                    <FormControl isRequired my={3}>
                        <FormLabel>Company Phone</FormLabel>
                        <Input type="string" name="phone" value={companyData.phone} onChange={inputChange} />
                    </FormControl>
                    <FormControl my={3}>
                        <FormLabel>Company Fax</FormLabel>
                        <Input type="string" name="fax" value={companyData.fax} onChange={inputChange} />
                    </FormControl>
                    <FormControl my={3}>
                        <FormLabel>Address <span style={{ color: "red" }}>*</span></FormLabel>
                        <Input
                            name="address_1" data-type="address" placeholder="Street Address" type="text" mb={4}
                            value={companyData.address_1} onChange={inputChange} isRequired
                        />
                        <Input
                            name="address_2" data-type="address" placeholder="Apartment number" type="text" mb={4}
                            value={companyData.address_2} onChange={inputChange}
                        />
                        <Input
                            name="city" data-type="address" placeholder="City" type="text" mb={4}
                            value={companyData.city} onChange={inputChange} isRequired
                        />
                        <Input
                            name="state" data-type="address" placeholder="State" type="text" mb={4}
                            value={companyData.state} onChange={inputChange} isRequired
                        />
                        <Input
                            name="country" data-type="address" placeholder="Country" type="text" mb={4}
                            value={companyData.country} onChange={inputChange} isRequired
                        />
                        <Input
                            name="zip" data-type="address" placeholder="Zipcode" type="text"
                            value={companyData.zip} onChange={inputChange} isRequired
                        />
                    </FormControl>
                    <Center>
                        <Text mt={'1.5rem'} mb={'1rem'}>Setup account owner using button below</Text>
                    </Center>
                    <GoogleButton phrase={"Sign Up Owner"} onClickFunction={googSignup} />

                    <Divider my={3} />
                    <Container centerContent>
                        <Button type='submit' colorScheme="green" my={3}>Save Information</Button>
                    </Container>
                </form>
                <Stack pt={6}>
                    <Text align={"center"}>
                        New user, existing company? <NavLink to="/signup/user" style={{ textDecoration: "underline", color: "blue" }}>User signup</NavLink>
                    </Text>
                </Stack>
            </Stack>
        </Box>
    )
}