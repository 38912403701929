import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "users",
    initialState: {
        users: [],
        createUserProgress: false,
        getUsersProgress: false,
        updateUserProgress: false,
        deleteUserProgress: false,
        error: false,
    },
    reducers: {
        // create user
        createUserStart: (state) => {
            state.createUserProgress = true
        },
        createUserSuccess: (state, action) => {
            state.users.push(action.payload)
            state.createUserProgress = false
        },
        createUserFailure: (state) => {
            state.error = true
            state.createUserProgress = false
        },

        // get user
        getUsersStart: (state) => {
            state.getUsersProgress = true
        },
        getUsersSuccess: (state, action) => {
            state.users = action.payload
            state.getUsersProgress = false
        },
        getUsersFailure: (state) => {
            state.error = true
            state.getUsersProgress = false
        },

        // update user
        updateUserStart: (state) => {
            state.updateUserProgress = true
        },
        updateUserSuccess: (state, action) => {
            // const index = state.users.indexOf(action.payload._id)
            state.updateUserProgress = false
        },
        updateUserFailure: (state) => {
            state.error = true
            state.updateUserProgress = false
        },

        // get user
        deleteUserStart: (state) => {
            state.deleteUserProgress = true
        },
        deleteUserSuccess: (state, action) => {
            state.users = state.users.filter(
                (user) => user._id !== action.payload
            )
            state.deleteUserProgress = false
        },
        deleteUserFailure: (state) => {
            state.error = true
            state.deleteUserProgress = false
        }

    },
})

export const {
    createUserStart,
    createUserSuccess,
    createUserFailure,
    getUsersStart,
    getUsersSuccess,
    getUsersFailure,
    updateUserStart,
    updateUserSuccess,
    updateUserFailure,
    deleteUserStart,
    deleteUserSuccess,
    deleteUserFailure,
} = userSlice.actions

export default userSlice.reducer