import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Divider,
    HStack
} from "@chakra-ui/react"
import { useState } from "react"
import handleInputChange from "../helper/inputChange"
import { createUser } from "../redux/User/userCalls"
import { useDispatch } from "react-redux"
import { useGoogleLogin } from '@react-oauth/google';
import GoogleButton from "../components/GoogleButton"
import { useNavigate } from "react-router-dom"
import { login } from "../redux/Auth/authCalls"

export default function SignupUser() {
    const [data, setData] = useState({
        email: "",
        role: "user",
    })
    const [accessKey, setAccessKey] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()

    function inputChange(e) {
        handleInputChange(e.target, setData)
    }

    function keyInputChange(e) {
        setAccessKey(e.target.value)
    }

    function handleCreateUser(e) {
        e.preventDefault()
        // HR - first check that form data is not empty strings, if this is true, we then send data to backend to validate
        // HR - if our backend successfully creates a user object, we clear the form
        if (data.email !== "" && data.password !== "" && accessKey !== "") {
            if (createUser({ user: data, key: accessKey }, dispatch)) {
                setData({
                    email: "",
                    role: "user"
                })
                setAccessKey("")
            }
        }
    }

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            login(tokenResponse.access_token, dispatch)
            navigate("/")
        }
    })

    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <Stack spacing={8} mt={20} w={"xl"} py={12} px={10}>
                <Stack align={"center"}>
                    <Heading fontSize={"4xl"} textAlign={"center"}>
                        Sign up
                    </Heading>
                    <Heading fontSize={"2xl"} textAlign={"center"}>
                        (User)
                    </Heading>
                </Stack>
                <Box
                    rounded={"lg"}
                    bg={useColorModeValue("white", "gray.700")}
                    boxShadow={"lg"}
                    p={8}
                >
                    <form onSubmit={handleCreateUser}>
                        <Stack spacing={4}>
                            <FormControl isRequired>
                                <FormLabel>Email address</FormLabel>
                                <Input type="email" name="email" data-type="user" value={data.email} onChange={inputChange} />
                            </FormControl>
                            {/* HR - TODO max input length will be 10 */}
                            <FormControl isRequired>
                                <FormLabel>Access Key</FormLabel>
                                <Input type="text" name="access_key" data-type="key" value={accessKey} onChange={keyInputChange} />
                            </FormControl>
                            <Stack spacing={10} pt={2}>
                                <Button
                                    type="submit"
                                    loadingText="Submitting"
                                    size="lg"
                                    bg={"blue.400"}
                                    color={"white"}
                                    _hover={{
                                        bg: "blue.500"
                                    }}
                                >
                                    Sign up
                                </Button>
                            </Stack>
                            <HStack pt={4} justifyContent={'center'}>
                                <Text align={"center"}>
                                    Already a user?
                                </Text>
                                <GoogleButton onClickFunction={googleLogin} phrase={"Login"} />
                            </HStack>
                        </Stack>
                    </form>
                </Box>
            </Stack>
        </Flex>
    )
}
