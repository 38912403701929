import { Flex, useColorModeValue, Stack, Heading, Text, Box, Image, HStack, Divider, AbsoluteCenter, VStack } from "@chakra-ui/react"
import itemizeTable from "../../images/itemize_table.png"
import contactPopup from "../../images/add_contact.png"
import productPopup from "../../images/add_product.png"
import productTable from "../../images/product_table.png"
import contactTable from "../../images/contact_table.png"
import reviseTables from "../../images/revise_tables.png"
import globalPopup from "../../images/global_discount.png"
import rowPopup from "../../images/row_discount.png"
import sortableList from "../../images/sortable_list.png"
import addText from "../../images/add_text.png"
import quoteHistory from "../../images/quote_history.png"
import editOptions from "../../images/edit_options.png"
import termsSettings from "../../images/terms_settings.png"
import paymentSettings from "../../images/payment_terms_settings.png"
import resources from "../../images/resources.png"
import sampleDoc from "../../images/sample_document.png"
import termsDoc from "../../images/terms_doc.png"
import Footer from "../../components/Footer"

export default function Features() {
    return (
        <>
            <Flex
                minH={"fit-content"}
                justify={"center"}
                bg={useColorModeValue("gray.50", "gray.800")}
                pt={"2rem"}
                overflowX={"scroll"}
            >
                <Stack
                    as={Box}
                    textAlign={'center'}
                    align={"center"}
                    spacing={{ base: 8, md: 14 }}
                    py={[10, 26, 30, 32]}
                    mx={"5rem"}
                    w={'100%'}>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '4xl', sm: '5xl', md: '6xl' }}
                        lineHeight={'110%'}>
                        What can
                        <Text as={'span'} color={'blue.400'} ml={'10px'} whiteSpace={'nowrap'}>
                            RapidToolz
                        </Text> do for you?
                    </Heading>
                    <Box position='relative' padding='10' width={"100%"}>
                        <Divider />
                        <AbsoluteCenter px='4'>
                            <Heading
                                fontWeight={500}
                                fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
                                lineHeight={'110%'}>
                                Information Storage
                            </Heading>
                        </AbsoluteCenter>
                    </Box>
                    <Stack direction={['column', 'column', 'column', 'column', 'row']} spacing={"3rem"} mb={'1rem'}>
                        <Image
                            alt={"Create Contact Image"}
                            src={contactPopup}
                        />
                        <Image
                            alt={"Create Product Image"}
                            src={productPopup}
                        />
                        <Text color={'gray.500'} fontSize={'large'}>
                            Create contacts and products to easily track and reuse information
                        </Text>
                    </Stack>
                    <Stack direction={['column', 'column', 'column', 'column', 'row']} spacing={"3rem"}>
                        <Text color={'gray.500'} fontSize={'large'}>
                            Edit and delete your contacts and products in your account tables
                        </Text>
                        <VStack spacing={"2rem"}>
                            <Image
                                alt={"Product Table Image"}
                                src={productTable}
                            />
                            <Image
                                alt={"Contact Table Image"}
                                src={contactTable}
                            />
                        </VStack>
                    </Stack>
                    <Box position='relative' padding='10' width={"100%"}>
                        <Divider />
                        <AbsoluteCenter px='4'>
                            <Heading
                                fontWeight={500}
                                fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
                                lineHeight={'110%'}>
                                Product Pricing
                            </Heading>
                        </AbsoluteCenter>
                    </Box>
                    <Stack direction={['column', 'column', 'column', 'column', 'row']} spacing={"3rem"} mb={'1rem'}>
                        <Image
                            alt={"Itemize Table Image"}
                            src={itemizeTable}
                        />
                        <Text color={'gray.500'} fontSize={'large'}>
                            Populate your order's table by importing your products
                        </Text>
                    </Stack>
                    <Stack direction={['column', 'column', 'column', 'column', 'row']} spacing={"3rem"} mb={'1rem'}>
                        <Text color={'gray.500'} fontSize={'large'}>
                            Customize your pricing per row, per order, or both!
                        </Text>
                        <Image
                            alt={"Global Discount Image"}
                            src={globalPopup}
                        />
                        <Image
                            alt={"Row Discount Image"}
                            src={rowPopup}
                        />
                    </Stack>
                    <Box position='relative' padding='10' width={"100%"}>
                        <Divider />
                        <AbsoluteCenter px='4'>
                            <Heading
                                fontWeight={500}
                                fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
                                lineHeight={'110%'}>
                                Document Customization
                            </Heading>
                        </AbsoluteCenter>
                    </Box>
                    <Stack direction={['column', 'column', 'column', 'column', 'row']} spacing={"3rem"} mb={'1rem'}>
                        <Image
                            alt={"Reorder Tables Image"}
                            src={reviseTables}
                        />
                        <Text color={'gray.500'} fontSize={'large'}>
                            Sort your products into multiple tables and chose between grand or itemized total(s)
                        </Text>
                    </Stack>
                    <Stack direction={['column', 'column', 'column', 'column', 'row']} spacing={"3rem"} mb={'1rem'}>
                        <Text color={'gray.500'} fontSize={'large'}>
                            Customize the layout of your PDF using text, tables, and spaces
                        </Text>
                        <Image
                            alt={"Add Text Image"}
                            src={addText}
                        />
                        <Image
                            alt={"Sortable Content Image"}
                            src={sortableList}
                        />
                    </Stack>
                    <Stack direction={['column', 'column', 'column', 'column', 'row']} spacing={"3rem"} mb={'1rem'}>
                        <Image
                            alt={"Sample Document Image"}
                            src={sampleDoc}
                        />
                        <Image
                            alt={"Terms Document Image"}
                            src={termsDoc}
                        />
                        <Text color={'gray.500'} fontSize={'large'}>
                            Here's a sample document made using RapidToolz
                        </Text>
                    </Stack>
                    <Box position='relative' padding='10' width={"100%"}>
                        <Divider />
                        <AbsoluteCenter px='4'>
                            <Heading
                                fontWeight={500}
                                fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
                                lineHeight={'110%'}>
                                Edit/View Documents
                            </Heading>
                        </AbsoluteCenter>
                    </Box>
                    <Text color={'gray.500'} fontSize={'large'}>
                        View, edit, and archive saved documents
                    </Text>
                    <Stack direction={['column', 'column', 'column', 'row']} spacing={"3rem"} mb={'1rem'}>
                        <Image
                            alt={"Quote History Image"}
                            src={quoteHistory}
                        />
                        <Image
                            alt={"Edit Options Image"}
                            src={editOptions}
                            w={'50%'}
                            mx={'auto'}
                        />
                    </Stack>
                    <Box position='relative' padding='10' width={"100%"}>
                        <Divider />
                        <AbsoluteCenter px='4'>
                            <Heading
                                fontWeight={500}
                                fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
                                lineHeight={'110%'}>
                                Account Settings
                            </Heading>
                        </AbsoluteCenter>
                    </Box>
                    <Text color={'gray.500'} fontSize={'large'} >
                        Manage your company's payment and delivery terms
                    </Text>
                    <Stack direction={['column', 'column', 'column', 'column', 'row']} spacing={"3rem"} mb={'1rem'}>
                        <Image
                            alt={"Payment Terms Image"}
                            src={paymentSettings}
                        />
                        <Image
                            alt={"Terms & Conditions Image"}
                            src={termsSettings}
                        />
                    </Stack>
                    <Box position='relative' padding='10' width={"100%"}>
                        <Divider />
                        <AbsoluteCenter px='4'>
                            <Heading
                                fontWeight={500}
                                fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
                                lineHeight={'110%'}>
                                Account Resources
                            </Heading>
                        </AbsoluteCenter>
                    </Box>
                    <Text color={'gray.500'} fontSize={'large'}>
                        Upload and store images and PDFs for easy access
                    </Text>
                    <HStack spacing={"3rem"}>
                        <Image
                            alt={"Resources Image"}
                            src={resources}
                        />
                    </HStack>
                </Stack>
            </Flex>
            <Footer />
        </>
    )
}