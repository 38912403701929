import FinalizePDF from "../../components/CreatePDF/FinalizePDF";
import { Flex, useColorModeValue, VStack } from "@chakra-ui/react";
import { useSelector } from "react-redux";

export default function ViewDocument() {
    const { edit_mode } = useSelector((state) => state.createDoc)
    const { doc_type } = useSelector((state) => state.createDoc)

    return (
        <Flex
            minH={"100vh"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
            pt={10}
            pb={10}
        >
            <VStack spacing={10} w={'100%'} mt={10}>
                <FinalizePDF type={doc_type} mode={edit_mode} />
            </VStack>
        </Flex>
    )
}