import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    HStack,
    Image
} from '@chakra-ui/react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uploadLogo, uploadResource } from '../redux/Resource/resourceCalls'

export default function AddResourcePopup({ isOpen, onClose, mode }) {
    const [uploadedFile, setUploadedFile] = useState()
    const [fileTitle, setFileTitle] = useState("")
    const [imageURL, setImageURL] = useState(null)
    const { account_id } = useSelector((state) => state.account)
    const { account_logo } = useSelector((state) => state.account)
    const dispatch = useDispatch()


    async function handleSubmitFile(e) {
        e.preventDefault()
        // HR - create formData object to easily be handle my backend multer library
        const formData = new FormData()
        formData.append("myFile", uploadedFile)
        formData.append("account_id", account_id)
        if (mode === "normal") {
            formData.append("myTitle", fileTitle)
            uploadResource(formData, dispatch)
        } else {
            formData.append("currentLogo", account_logo)
            formData.append("myTitle", 'Logo')
            uploadLogo(formData, dispatch)
        }
        onClose()
        setImageURL(null)
        setFileTitle("")
        document.getElementById("fileInput").value = null
    }

    // HR - this popup is a template for a form that will be developed later, it does nothing
    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{mode === "normal" ? "Upload New Resource" : "Upload New Logo"}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form onSubmit={handleSubmitFile}>
                            <VStack spacing={"2rem"}>
                                <FormControl isRequired>
                                    <FormLabel>{mode === "normal" ? "Upload File" : "Upload Image"}</FormLabel>
                                    <Input placeholder='Choose File' id="fileInput" type="file" accept={mode === "normal" ? 'image/*,.pdf' : 'image/*'} variant={"flushed"} onChange={(e) => setUploadedFile(e.target.files[0])} />
                                </FormControl>
                                {mode === "normal" && <FormControl isRequired>
                                    <FormLabel>Title</FormLabel>
                                    <Input placeholder={'Resource Title'} type="text" variant={"flushed"} value={fileTitle} onChange={(e) => setFileTitle(e.target.value)} />
                                </FormControl>}
                            </VStack>
                            <HStack mt={'2rem'}>
                                <Button colorScheme='blue' mr={3} type="submit">
                                    Save
                                </Button>
                                <Button onClick={onClose}>Cancel</Button>
                            </HStack>
                        </form>
                        {imageURL && <Image src={imageURL} />}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}