import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import NavComplete from './components/NavComplete';
import NavSignedOut from './components/NavSignedOut'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home'
import POHistory from './pages/POHistory';
import QuoteHistory from './pages/QuoteHistory';
import InvoiceHistory from './pages/InvoiceHistory';
import DraftHistory from "./pages/DraftHistory";
import Contacts from './pages/Contacts'
import Products from './pages/Products'
import Resources from './pages/Resources'
import Settings from './pages/Settings'
import CreateQuote from './pages/Create/CreateQuote';
import CreatePO from './pages/Create/CreatePO';
import SignupUser from './pages/SignupUser';
import SignupCompany from './pages/SignupCompany';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import EditPO from './pages/Edit/EditPO';
import EditQuote from './pages/Edit/EditQuote';
import EditInvoice from './pages/Edit/EditInvoice';
import EditOptions from "./pages/Edit/EditOptions";
import ReportBug from "./pages/ReportBug";
import Features from "./pages/SignedOut/Features"
import Pricing from "./pages/SignedOut/Pricing"
import AboutUs from "./pages/SignedOut/AboutUs"
import ContactUs from "./pages/SignedOut/ContactUs"
import NotFound from "./pages/NotFound";
import { getResources } from "./redux/Resource/resourceCalls";
import { getAccountById } from "./redux/Account/accountCalls";
import { useEffect } from "react";
import { switchTheme } from "./styles/CustomSwitch";
import ViewDocument from "./pages/View/ViewDocument";
import HomeSO from "./pages/SignedOut/HomeSO";
import { getPaymentTerms } from "./redux/PaymentTerm/paymentTermCalls";
import { getProducts } from "./redux/Product/productCalls";
import { getContacts } from "./redux/Contact/contactCalls";
import { getDocuments } from "./redux/Document/documentCalls";
import NavTest from "./components/NavSignedOut";


const theme = extendTheme({
  components: {
    Switch: switchTheme
  }
})

function App() {
  const { user_id } = useSelector((state) => state.auth)
  const { account_id } = useSelector((state) => state.account)
  const { status } = useSelector((state) => state.account.subscription)
  const dispatch = useDispatch()

  useEffect(() => {
    // HR - refetch account every 10 minutes so that  account info is updated
    const accountInterval = setInterval(() => {
      if (account_id) {
        getAccountById(account_id, dispatch)
      }
    }, 600000)
    return () => clearInterval(accountInterval)
  }, [])

  useEffect(() => {
    // HR - refetch products every 10 minutes
    const productInterval = setInterval(() => {
      if (account_id) {
        getProducts(account_id, dispatch)
      }
    }, 600000)
    return () => clearInterval(productInterval)
  }, [])

  useEffect(() => {
    // HR - refetch products every 10 minutes
    const contactInterval = setInterval(() => {
      if (account_id) {
        getContacts(account_id, dispatch)
      }
    }, 600000)
    return () => clearInterval(contactInterval)
  }, [])

  useEffect(() => {
    // HR - refetch products every 10 minutes
    const documentInterval = setInterval(() => {
      if (account_id) {
        getDocuments(account_id, dispatch)
      }
    }, 600000)
    return () => clearInterval(documentInterval)
  }, [])

  useEffect(() => {
    // HR - refetch resources every hour so that signed URLs do not expire
    const resourceInterval = setInterval(() => {
      if (account_id) {
        getResources(account_id, dispatch)
      }
    }, 3600000)
    return () => clearInterval(resourceInterval)
  }, [])

  useEffect(() => {
    // HR - refetch payment terms every hour
    const payTermInterval = setInterval(() => {
      if (account_id) {
        getPaymentTerms(account_id, dispatch)
      }
    }, 3600000)
    return () => clearInterval(payTermInterval)
  }, [])


  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}>
      {/* HR - ChakraProvider is necessary to use chakraUI components */}
      <ChakraProvider theme={theme}>
        {/* HR - all routes go inside BrowserRouter component */}
        <BrowserRouter>
          {/* HR - NavComplete includes the navbar at the top, and the drawer on the left */}
          {user_id ? <NavComplete /> : <NavSignedOut />}
          <Routes>
            {/* HR - if user_id exists (redux state) then we show signed-in home page, otherwise show signup */}
            {user_id ?
              <>
                <Route exact path="/" element={<Home />} />
              </>
              : <Route exact path="/" element={<HomeSO />} />
            }
            {(user_id && status === "active") && <>
              <Route exact path="/create/quote" element={<CreateQuote />} />
              <Route exact path="/create/purchaseorder" element={<CreatePO />} />
              <Route exact path="/edit/options" element={<EditOptions />} />
              <Route exact path="/edit/quote" element={<EditQuote />} />
              <Route exact path="/edit/purchaseorder" element={<EditPO />} />
              <Route exact path="/edit/invoice" element={<EditInvoice />} />
              <Route exact path="/view/quote" element={<ViewDocument />} />
              <Route exact path="/view/purchaseorder" element={<ViewDocument />} />
              <Route exact path="/view/invoice" element={<ViewDocument />} />
              <Route exact path="/history/quote" element={<QuoteHistory />} />
              <Route exact path="/history/purchaseorder" element={<POHistory />} />
              <Route exact path="/history/invoice" element={<InvoiceHistory />} />
              <Route exact path="/history/draft" element={<DraftHistory />} />
              <Route exact path="/contacts" element={<Contacts />} />
              <Route exact path="/products" element={<Products />} />
              <Route exact path="/resources" element={<Resources />} />
              <Route exact path="/settings" element={<Settings />} />
              <Route exact path="/reportbug" element={<ReportBug />} />
            </>}
            <Route exact path="/signup/user" element={<SignupUser />} />
            <Route exact path="/signup/company" element={<SignupCompany />} />
            <Route exact path="/features" element={<Features />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/about" element={<AboutUs />} />
            <Route exact path="/contactus" element={<ContactUs />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
