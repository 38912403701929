import axios from 'axios'
import { toast } from 'react-toastify'
import * as actions from "./resourceSlice"
import { deleteTermsSuccess, updateAccountSuccess } from '../Account/accountSlice'
import getApiUri from '../../helper/getApiUri'

const apiUrl = getApiUri() + "/resources"

export const uploadResource = async (formData, dispatch) => {
    dispatch(actions.createResourceStart())
    try {
        const { data } = await axios.post(apiUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        dispatch(actions.createResourceSuccess(data.data))
        toast.success(data.message)
        return true
    } catch (error) {
        console.log(error)
        dispatch(actions.createResourceFailure())
        toast.error(error.message)
        return false
    }
}

export const uploadLogo = async (formData, dispatch) => {
    dispatch(actions.createResourceStart())
    try {
        const { data } = await axios.post(apiUrl + '/logo', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        // HR - if uploading a logo, also update account info to include new logo's id
        dispatch(actions.createResourceSuccess(data.resource))
        dispatch(updateAccountSuccess(data.account))
        toast.success(data.message)
        return true
    } catch (error) {
        console.log(error)
        dispatch(actions.createResourceFailure())
        toast.error(error.response.data.message)
        return false
    }
}

export const uploadTerms = async (formData, dispatch) => {
    dispatch(actions.createResourceStart())
    try {
        const { data } = await axios.post(apiUrl + '/terms', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        // HR - if uploading a T&C, also update account info to include new term's id
        dispatch(actions.createResourceSuccess(data.resource))
        dispatch(updateAccountSuccess(data.account))
        toast.success(data.message)
        return true
    } catch (error) {
        console.log(error)
        dispatch(actions.createResourceFailure())
        toast.error(error.response.data.message)
        return false
    }
}

export const getResources = async (payload, dispatch) => {
    dispatch(actions.getResourcesStart())
    try {
        const { data } = await axios.get(apiUrl + `/${payload}`)
        dispatch(actions.getResourcesSuccess(data.data))
        return true
    } catch (error) {
        dispatch(actions.getResourcesFailure())
        return false
    }
}

export const deleteResource = async (id, dispatch) => {
    dispatch(actions.deleteResourceStart())
    try {
        const { data } = await axios.delete(apiUrl + "/" + id)
        dispatch(actions.deleteResourceSuccess(id))
        toast.success(data.message)
        return true
    } catch (error) {
        toast.error("Something went wrong!");
        dispatch(actions.deleteResourceFailure())
        return false
    }
}

export const deleteTerms = async (id, dispatch) => {
    dispatch(actions.deleteResourceStart())
    try {
        const { data } = await axios.delete(apiUrl + "/terms/" + id)
        dispatch(actions.deleteResourceSuccess(id))
        dispatch(deleteTermsSuccess())
        toast.success(data.message)
        return true
    } catch (error) {
        toast.error("Something went wrong!");
        dispatch(actions.deleteResourceFailure())
        return false
    }
}