import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
    name: "products",
    initialState: {
        products: [],
        createProductProgress: false,
        getProductsProgress: false,
        editProductProgress: false,
        deleteProductProgress: false,
        error: false,
    },
    reducers: {
        // create product
        createProductStart: (state) => {
            state.createProductProgress = true
        },
        createProductSuccess: (state, action) => {
            state.products.push(action.payload)
            state.createProductProgress = false
        },
        createProductFailure: (state) => {
            state.error = true
            state.createProductProgress = false
        },

        // get product
        getProductsStart: (state) => {
            state.getProductsProgress = true
        },
        getProductsSuccess: (state, action) => {
            state.products = action.payload
            state.getProductsProgress = false
        },
        getProductsFailure: (state) => {
            state.error = true
            state.getProductsProgress = false
        },

        // update product
        updateProductStart: (state) => {
            state.updateProductProgress = true
        },
        updateProductSuccess: (state, action) => {
            state.updateProductProgress = false
        },
        updateProductFailure: (state) => {
            state.error = true
            state.updateProductProgress = false
        },

        // get product
        deleteProductStart: (state) => {
            state.deleteProductProgress = true
        },
        deleteProductSuccess: (state, action) => {
            state.products = state.products.filter(
                (product) => product._id !== action.payload
            )
            state.deleteProductProgress = false
        },
        deleteProductFailure: (state) => {
            state.error = true
            state.deleteProductProgress = false
        }

    },
})

export const {
    createProductStart,
    createProductSuccess,
    createProductFailure,
    getProductsStart,
    getProductsSuccess,
    getProductsFailure,
    updateProductStart,
    updateProductSuccess,
    updateProductFailure,
    deleteProductStart,
    deleteProductSuccess,
    deleteProductFailure,
} = productSlice.actions

export default productSlice.reducer